import { Input, InputProps, Label } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import * as React from 'react';

type InputWrapperProps = InputProps & { label?: string; error?: boolean; helperText?: string };

export const InputWrapper = React.forwardRef<HTMLInputElement, InputWrapperProps>(
  ({ className, label, error, helperText, ...props }, ref) => {
    return (
      <div className="flex flex-col gap-2">
        {label ? <Label>{label}</Label> : null}
        <Input
          {...props}
          className={cn(className, {
            'border border-destructive focus-visible:outline-none focus-visible:ring-destructive': error,
          })}
          ref={ref}
        />
        {helperText ? (
          <p
            className={cn('text-destructive-foreground', {
              'bg-destructive/25': error,
            })}
          >
            {helperText}
          </p>
        ) : null}
      </div>
    );
  }
);
InputWrapper.displayName = 'InputWrapper';
