import { useEffect } from 'react';
import { config } from 'app/config/main';
import { useAppLayout } from 'app/utils/AppLayout';

export const Page = ({ component, layout = config.defaultLayout, ...restProps }) => {
  const { activeLayout, setActiveLayout } = useAppLayout();

  useEffect(() => {
    if (layout !== activeLayout) {
      setActiveLayout(layout);
    }
  }, [layout, setActiveLayout, activeLayout]);

  const PageComponent = component;

  return <PageComponent {...restProps} />;
};
