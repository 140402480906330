import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const TermsOfService = () => {
  return (
    <Card>
      <CardContent
        sx={{
          pt: 0,
          marginTop: '50px',
          marginRight: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <Typography
          style={{ whiteSpace: 'pre-line' }}
          fontSize={'16px'}
          variant={'body1'}
          color="text.secondary"
          mb={2}
        >
          {`Terms of Service

            Effective Date: 08/03/2023

            Welcome to Cosmos Technologies, Inc ("Cosmos Technologies," "we," "our," or "us"). By accessing or using our web application ("the Web Application"), you agree to be bound by these Terms of Service ("Terms"). Please read these Terms carefully before using the Web Application. If you do not agree to these Terms, you must not use our services.

            Use of the Web Application
            1.1 Eligibility
            You must be at least 18 years old and have the legal capacity to enter into a binding agreement to use the Web Application. By using the Web Application, you represent and warrant that you meet these eligibility requirements.

            1.2 License
            Subject to your compliance with these Terms, Cosmos Technologies grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Web Application for your personal or business purposes.

            1.3 Prohibited Activities
            You agree not to:
            a) Use the Web Application for any unlawful or unauthorized purpose;
            b) Interfere with or disrupt the integrity or performance of the Web Application or its underlying infrastructure;
            c) Attempt to gain unauthorized access to the Web Application or its related systems or networks;
            d) Modify, adapt, translate, or create derivative works based on the Web Application or any portion thereof;
            e) Remove, obscure, or modify any copyright, trademark, or other proprietary rights notices on the Web Application;
            f) Use any automated system, including "bots" or "spiders," to access, scrape, or index the Web Application;
            g) Use the Web Application to transmit any viruses, malware, or harmful code; and
            h) Violate any applicable laws or regulations while using the Web Application.

            1.4 Account Registration
            To access certain features of the Web Application, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must promptly notify us of any unauthorized use or security breaches related to your account.

            Intellectual Property
            2.1 Ownership
            The Web Application and all its content, including but not limited to text, graphics, images, logos, trademarks, and software, are the property of Cosmos Technologies or its licensors and are protected by intellectual property laws.

            2.2 License Grant by Users
            By submitting or uploading any content to the Web Application, you grant Cosmos Technologies a worldwide, royalty-free, non-exclusive license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content for the purpose of providing and improving the Web Application.

            Privacy
            Your use of the Web Application is subject to our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy [www.app.golivecosmos.com/privacy] to understand how we collect, use, and disclose your personal information.

            Termination
            Cosmos Technologies reserves the right to suspend or terminate your access to the Web Application at any time and for any reason without notice. Upon termination, your right to use the Web Application will cease, and you must promptly discontinue all use of the Web Application.

            Disclaimer of Warranties
            THE WEB APPLICATION IS PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS. COSMOS TECHNOLOGIES DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE WEB APPLICATION WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.

            Limitation of Liability
            IN NO EVENT SHALL COSMOS TECHNOLOGIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEB APPLICATION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

            Indemnification
            You agree to indemnify, defend, and hold harmless Cosmos Technologies, its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Web Application or violation of these Terms.

            Governing Law and Jurisdiction
            These Terms shall be governed by and construed in accordance with the laws of the state of Delaware. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Delaware.

            Changes to the Terms
            Cosmos Technologies may update these Terms from time to time. The most current version will be posted on the Web Application, and your continued use of the Web Application after the changes are posted will constitute your acceptance of the updated Terms.

            Contact Us
            If you have any questions, concerns, or feedback about these Terms, please contact us at support@golivecosmos.com.

            By using the Web Application, you signify your agreement to these Terms. If you do not agree to these Terms, please do not use the Web Application.

            Last Updated: 08/03/2023`}
        </Typography>
      </CardContent>
    </Card>
  );
};

export { TermsOfService };
