import React, { useEffect, useState } from 'react';
import { Alert, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PricePlan from './PricePlan';
import FeaturesList from './FeaturesList';
import billingServices from 'app/services/billing-services';
import { loadStripe } from '@stripe/stripe-js';
import { config } from 'app/config/main';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import featuresServices from 'app/services/features-services';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useJumboTheme } from '@jumbo/hooks';

const selectedSx = {
  transform: 'scale(1)',
  color: 'common.white',
  bgcolor: 'primary.light',
};

export const PricingPlan = () => {
  const stripePromise = loadStripe(config.env.STRIPE_PUBLISHABLE_KEY);

  const [currentBillPlan, setCurrentBillPlan] = useState<{ attributes?: { planName: string } }>({});
  const [paymentIntent, setPaymentIntent] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  // @ts-ignore
  const { theme } = useJumboTheme();

  const Swal = useSwalWrapper();
  const successSweetAlert = () => {
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Your organization is signed up for the Cosmos developer plan.',
      timer: 3000,
    });
  };

  const errorSweetAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Something went wrong finding this pricing plan. Please try again or email support@golivecosmos.com',
    });
  };

  const handleClose = () => {
    setOpen(false);
    setPaymentIntent(false);
  };

  const pricePlanIsSelected = (planName) => {
    return currentBillPlan && currentBillPlan?.attributes?.planName === planName;
  };

  const selectPlan = async (event) => {
    setErrorMessage('');
    const {
      target: { id },
    } = event;

    try {
      const {
        attributes: { clientSecret },
      } = await billingServices.createBilling({ body: { planName: id } });
      if (clientSecret && id !== 'developer') {
        setPaymentIntent(true);
        setClientSecret(clientSecret);
        setOpen(true);
      } else {
        successSweetAlert();
        setSuccess(true);
      }
    } catch (error) {
      errorSweetAlert();
    }
  };

  const renderPricePlan = (planName, price, displayHeader) => {
    if (!isMounted) {
      return;
    }

    const isSelected = !!pricePlanIsSelected(planName);
    return (
      <PricePlan
        title={price}
        subheader={displayHeader}
        headerSx={{
          textAlign: 'center',
          bgcolor: isSelected ? 'primary.dark' : 'primary.main',
        }}
        sx={isSelected ? selectedSx : { border: `1px solid ${theme.palette.primary.main}` }}
      >
        <FeaturesList features={featuresServices.getFeaturesList(planName)} />
        <Button
          id={planName}
          variant={'contained'}
          disableElevation
          sx={{ mb: 2, display: isSelected ? 'none' : 'flex' }}
          onClick={selectPlan}
        >
          Buy Now
        </Button>
      </PricePlan>
    );
  };

  useEffect(() => {
    async function fetchBillPlanFromUrl() {
      const billing = await billingServices.getBilling();
      if (!ignore) {
        setIsMounted(true);
        if (billing.id) {
          setCurrentBillPlan(billing);
        }
      }
    }

    let ignore = false;
    fetchBillPlanFromUrl();
    return () => {
      ignore = true;
    };
  }, [success, open]);

  return (
    <React.Fragment>
      <Typography variant="h2" mb={4}>
        Pricing Plans
      </Typography>
      {errorMessage && (
        <Alert sx={{ margin: '0 0 20px 0' }} severity="error">
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={3.75} mb={4}>
        <Grid item xs={12} md={6} lg={4}>
          {renderPricePlan('basic', 'Free', 'Basic')}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {renderPricePlan('pro', '$20/month', 'Pro')}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {renderPricePlan('enterprise', '$150/month', 'Enterprise')}
        </Grid>
      </Grid>

      {paymentIntent && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle style={{ textAlign: 'center', marginTop: '10px', fontSize: '1.5rem' }}>
            Payment Details
          </DialogTitle>
          <DialogContent>
            <Grid item style={{ padding: '0px 50px' }}>
              <Elements
                options={{
                  clientSecret,
                  appearance: {
                    theme: 'night',
                    variables: {
                      colorPrimary: '#7352C7',
                      colorText: '#FFFFFF',
                      spacingUnit: '4px',
                    },
                  },
                }}
                stripe={stripePromise}
              >
                <CheckoutForm />
              </Elements>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default PricingPlan;
