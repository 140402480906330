import { LAYOUT_NAMES } from 'app/layouts/layouts';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface AppLayout {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  activeLayout: LAYOUT_NAMES;
  setActiveLayout: (v: LAYOUT_NAMES) => void;
}

export const AppLayoutContext = createContext<AppLayout>({
  sidebarOpen: true,
  setSidebarOpen: () => {
    throw new Error('Not implemented');
  },
  activeLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
  setActiveLayout: () => {},
});

export const AppLayoutProvider = AppLayoutContext.Provider;

export const useAppLayout = () => useContext(AppLayoutContext);
