import { createContext } from 'react';

const JumboAppContext = createContext<{
  rebuildRoutes: boolean;
  setRebuildRoutes: (v: boolean) => void;
}>({
  rebuildRoutes: false,
  setRebuildRoutes: () => {},
});

export default JumboAppContext;
