import { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from 'recharts';
import { AlertColor } from '@mui/material/Alert/Alert';
import projectServices, { WeatherParams } from 'app/services/project-services';
import { extractError } from 'app/utils/appHelpers';
import { Sun, CloudRain, Plus } from 'lucide-react';

const SERVICE_METRICS = {
  electric: 'total_usage_kwh',
  steam: 'total_steam_use_mlb',
  gas: 'total_gas_use_therms',
  oil: 'units',
};

interface DaysProps {
  temp: number;
  tempmax: number;
}

export interface WeatherProps {
  address: string;
  days: DaysProps[];
}
interface VisualizationsProps {
  projectId: string;
  onSnackbar: (message: string, severity: AlertColor) => void;
}

interface InsightCardProps {
  title: string;
  value: string | number;
  unit?: string;
}

interface DocumentData {
  months: Array<{
    billing_end_date: string;
    billing_start_date: string;
    billing_summary_date: string;
    total_usage_kwh: number;
  }>;
  startDate: string;
  endDate: string;
  stats: {
    loadFactor: number;
    avgLoad: number;
    totalUsage: number;
    totalCost: number;
  };
}

interface Documents {
  [key: string]: DocumentData;
}

interface MainChartProps {
  data: any[];
  metric: string;
  weatherData?: WeatherProps;
  units: string;
}

const InsightCard = ({ title, value, unit }: InsightCardProps) => (
  <div className="bg-dark hover:bg-dark-light rounded-lg p-6 shadow-lg transition duration-300">
    <h3 className="text-primary-light mb-3 text-xl font-semibold">{title}</h3>
    <p className="text-light text-2xl font-bold">
      {value}
      {unit && <span className="text-light-dark ml-2 text-xl font-normal">{unit}</span>}
    </p>
  </div>
);

const MainChart = ({ data, metric, weatherData, units }: MainChartProps) => (
  <ResponsiveContainer width="100%" height={300}>
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="monthYear" />
      <YAxis
        yAxisId="left"
        orientation="left"
        label={{ value: units, angle: -90, position: 'insideLeft', fill: '#E0E0E0' }}
      />
      {weatherData && (
        <YAxis
          label={{ value: 'Degrees (°F)', angle: -90, position: 'insideRight', fill: '#E0E0E0' }}
          yAxisId="right"
          orientation="right"
        />
      )}
      <Tooltip
        contentStyle={{ backgroundColor: '#2D2D2D', border: 'none', borderRadius: '4px' }}
        labelStyle={{ color: '#E0E0E0' }}
        itemStyle={{ color: '#E0E0E0' }}
      />
      <Legend />
      <Line
        yAxisId="left"
        unit={` ${units}`}
        name={units}
        type="linear"
        dataKey={metric}
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
      {weatherData && (
        <Line unit=" °F" name="Temperature" yAxisId="right" type="linear" dataKey="temperature" stroke="#82ca9d" />
      )}
    </LineChart>
  </ResponsiveContainer>
);

export const Visualizations = ({ projectId, onSnackbar }: VisualizationsProps) => {
  const [documents, setDocuments] = useState<Documents>({});
  const [isLoading, setIsLoading] = useState(true);
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({});
  const [isAddingWeather, setIsAddingWeather] = useState(false);
  const [zipCode, setZipCode] = useState('');

  const [weather, setWeather] = useState({} as WeatherProps);
  const [degreeData, setDegreeData] = useState({});
  const [baseloads, setBaseloads] = useState({});

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchProjectInsights = async () => {
      try {
        const insightsParams = { id: projectId, data: { baseTemp: 65, degreeDataTypes: ['hdd', 'cdd'] } };
        const regressionResult = await projectServices.fetchProjectInsights(insightsParams);

        if (isMounted) {
          const { degreeData, baseloads, formattedDocuments, services, weather } = regressionResult;
          setDocuments(formattedDocuments);
          setExpandedSections(services.reduce((acc, key) => ({ ...acc, [key]: true }), {}));

          setWeather(weather);
          setBaseloads(baseloads);
          setDegreeData(degreeData);
        }
      } catch (error) {
        if (isMounted) {
          onSnackbar(extractError(error) || 'Failed to fetch project insights.', 'error');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchProjectInsights();

    return () => {
      isMounted = false;
    };
  }, [projectId, onSnackbar]);

  if (isLoading) {
    return <div className="py-8 text-center">Loading...</div>;
  }

  const renderLoadMetric = (serviceType: string) => {
    switch (serviceType) {
      case 'electric':
        return 'kWh';
      case 'gas':
        return 'therms';
      case 'steam':
        return 'Mlbs';
      case 'oil':
        return 'gallons';
      default:
        return 'Unknown metric';
    }
  };

  const formatValue = (value: number | undefined, isPercentage: boolean = false) => {
    if (!value) return '0';

    if (isPercentage) {
      return `${(value * 100).toFixed(2)}%`;
    }
    return value.toLocaleString();
  };

  const getTrendData = (serviceType: string, degreeDayType: string) => {
    if (!documents[serviceType]) return [];

    const hasBaseloads = !!baseloads?.[serviceType];
    const hasWeather = !!weather;
    const hasDegreeData = !!degreeData;

    return documents[serviceType].months.map((month) => {
      const isOil = serviceType === 'oil';
      const [m, d, y] = isOil ? month.billing_summary_date.split('-') : month.billing_start_date.split('-');
      const monthDayYear = `${m}-${d}-${y}`;
      const monthYear = `${m}-${y}`;
      const result: any = {
        monthYear: isOil ? monthDayYear : monthYear,
        totalUsage: month[SERVICE_METRICS[serviceType]],
      };

      if (hasWeather && weather[monthYear]) {
        result.temperature = weather[monthYear]['avg'];
      }

      if (hasBaseloads) {
        result.baseload = baseloads[serviceType].baseload;
      }

      if (hasDegreeData && degreeData[monthYear]) {
        result.degreeDay = degreeData[monthYear][degreeDayType];
        result.degreeDayMetric = degreeDayType;
      }

      return result;
    });
  };

  const toggleSection = (serviceType: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [serviceType]: !prev[serviceType],
    }));
  };

  const toggleAddWeather = () => {
    setIsAddingWeather(!isAddingWeather);
  };

  const handleAddWeather = async () => {
    try {
      const startDate = Object.values(documents).reduce((earliest, doc) => {
        const { startDate } = doc;
        return startDate !== '' && startDate < earliest ? startDate : earliest;
      }, Object.values(documents)[0].startDate);

      const endDate = Object.values(documents).reduce((latest, doc) => {
        const { endDate } = doc;
        return endDate !== '' && endDate > latest ? endDate : latest;
      }, Object.values(documents)[0].endDate);

      const params: WeatherParams = {
        id: projectId,
        data: { zipCode, startDate, endDate },
      };

      const savedProject = await projectServices.addWeather(params);
      if (savedProject) {
        onSnackbar('Weather data added successfully.', 'success');
        setIsAddingWeather(false);
      }
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to add weather data.', 'error');
    }
  };

  const renderWeatherIcon = () => {
    if (weather && Object.keys(weather).length > 0) {
      return <Sun className="h-6 w-6 text-yellow-400" />;
    } else {
      return (
        <button
          onClick={toggleAddWeather}
          className="hover:text-primary-light flex items-center text-primary transition-colors duration-300"
        >
          <CloudRain className="mr-2 h-6 w-6" />
          <Plus className="h-4 w-4" />
        </button>
      );
    }
  };

  return (
    <div className="bg-dark text-light container mx-auto px-4 py-2">
      <div className="flex items-center justify-between gap-4">
        <h1 className="mb-8 text-2xl font-bold text-primary">Energy Usage Dashboard</h1>
        <div className="flex items-center gap-6">
          <span className="text-light-dark mr-2">Weather Data:</span>
          {renderWeatherIcon()}
        </div>
      </div>

      {isAddingWeather && (
        <div className="bg-dark-lighter mb-8 rounded-lg p-6">
          <h4 className="text-primary-light mb-4 text-2xl font-semibold">Add Weather Data</h4>
          <p className="mb-4 text-lg">Enter the ZIP code for this location:</p>
          <input
            type="text"
            className="border-light-dark mr-4 rounded-lg border bg-[#111111] px-4 py-2 focus:border-primary focus:outline-none"
            placeholder="ZIP Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
          <button
            className="hover:bg-primary-dark text-light rounded-lg bg-primary px-6 py-3 font-semibold transition duration-300"
            onClick={handleAddWeather}
          >
            Submit
          </button>
        </div>
      )}

      {Object.keys(expandedSections).map((serviceType) => (
        <div key={serviceType} className="border-dark-lighter mb-8 overflow-hidden rounded-lg border">
          <button
            className="bg-dark-light hover:bg-dark-lighter flex w-full items-center justify-between p-6 text-left transition duration-300 focus:outline-none"
            onClick={() => toggleSection(serviceType)}
          >
            <h2 className="text-primary-light text-xl font-semibold capitalize">{serviceType} Usage</h2>
            <span className="text-3xl text-primary">{expandedSections[serviceType] ? '−' : '+'}</span>
          </button>
          {expandedSections[serviceType] && (
            <div className="bg-dark-lighter p-6">
              <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
                {serviceType === 'electric' && (
                  <InsightCard
                    title="Load Factor"
                    value={formatValue(documents[serviceType]?.stats?.loadFactor, true)}
                  />
                )}
                <InsightCard
                  title="Average Load"
                  value={formatValue(documents[serviceType]?.stats?.avgLoad)}
                  unit={renderLoadMetric(serviceType)}
                />
                <InsightCard
                  title="Total Usage"
                  value={formatValue(documents[serviceType]?.stats?.totalUsage)}
                  unit={renderLoadMetric(serviceType)}
                />
                <InsightCard
                  title="Total Cost"
                  value={`$${formatValue(documents[serviceType]?.stats?.totalCost, false)}`}
                />
                {!!baseloads[serviceType] && (
                  <InsightCard
                    title={`${serviceType === 'electric' ? 'Cooling' : 'Heating'} baseload`}
                    value={formatValue(baseloads[serviceType].baseload)}
                    unit={renderLoadMetric(serviceType)}
                  />
                )}
              </div>
              <div className="bg-dark mb-8 rounded-lg p-6 shadow-lg">
                <h3 className="text-primary-light mb-6 text-2xl font-semibold">Usage Trend</h3>
                <MainChart
                  data={getTrendData(serviceType, serviceType === 'electric' ? 'cdd' : 'hdd')}
                  metric="totalUsage"
                  weatherData={weather}
                  units={renderLoadMetric(serviceType)}
                />
              </div>
              <div className="bg-dark mb-8 h-[500px] rounded-lg p-6 shadow-lg">
                <h3 className="text-primary-light mb-6 text-2xl font-semibold">Usage vs. Degree Days</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart data={getTrendData(serviceType, serviceType === 'electric' ? 'cdd' : 'hdd')}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                    <XAxis dataKey="monthYear" tick={{ fill: '#E0E0E0' }} />
                    <YAxis
                      yAxisId="left"
                      label={{
                        value: serviceType === 'electric' ? 'CDD' : 'HDD',
                        angle: -90,
                        position: 'insideLeft',
                        fill: '#E0E0E0',
                      }}
                      tick={{ fill: '#E0E0E0' }}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      label={{
                        value: renderLoadMetric(serviceType),
                        angle: -90,
                        position: 'insideRight',
                        fill: '#E0E0E0',
                      }}
                      tick={{ fill: '#E0E0E0' }}
                    />
                    <Tooltip
                      contentStyle={{ backgroundColor: '#2D2D2D', border: 'none', borderRadius: '4px' }}
                      labelStyle={{ color: '#E0E0E0' }}
                      itemStyle={{ color: '#E0E0E0' }}
                    />
                    <Legend wrapperStyle={{ color: '#E0E0E0' }} />
                    <Bar
                      yAxisId="left"
                      dataKey="degreeDay"
                      fill="#7352C7"
                      name={serviceType === 'electric' ? 'CDD' : 'HDD'}
                    />
                    <Line
                      yAxisId="right"
                      type="linear"
                      dataKey="totalUsage"
                      stroke="#ff7300"
                      name={renderLoadMetric(serviceType)}
                      dot={{ r: 4 }}
                      activeDot={{ r: 6 }}
                    />
                    <Line
                      yAxisId="right"
                      type="linear"
                      dataKey="baseload"
                      stroke="#E0E0E0"
                      name={`Baseload (${renderLoadMetric(serviceType)})`}
                      dot={{ r: 4 }}
                      activeDot={{ r: 6 }}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
