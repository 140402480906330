import { cn } from '@shadcn/utils';
import { useAppLayout } from 'app/utils/AppLayout';
import { menus } from './menus';
import { SidebarHeader } from './SidebarHeader';
import { VerticalNavbar } from './VerticalNavbar';

export const Sidebar = () => {
  const { sidebarOpen } = useAppLayout();
  return (
    <div
      className={cn('flex h-full flex-col items-stretch gap-10 bg-card py-4', {
        'pr-4': sidebarOpen,
        'items-center': !sidebarOpen,
      })}
    >
      <SidebarHeader />
      <VerticalNavbar items={menus} />
    </div>
  );
};
