import React from 'react';
import { Button, Container, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const ShowSecretKeyModal = ({ handleClose, secretKey }) => {
  return (
    <Container>
      <DialogTitle sx={{ display: 'flex', fontSize: '1.25rem', justifyContent: 'space-evenly' }}>
        Copy your API token. You will not see this again
        <Tooltip title="Copy API token" arrow>
          <ContentCopyIcon
            sx={{ cursor: 'pointer' }}
            onClick={async () => await navigator.clipboard.writeText(secretKey)}
          />
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ minWidth: '400px', wordWrap: 'break-word' }}>
        <Typography>{secretKey}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ backgroundColor: 'transparent' }} color="inherit">
          Close
        </Button>
      </DialogActions>
    </Container>
  );
};
