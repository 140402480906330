import { Suspense, useEffect } from 'react';
import { Toaster } from '@shadcn/ui';
// unused?
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import JumboApp from '@jumbo/components/JumboApp';
import { AppLayout } from './AppLayout';
import JumboTheme from '@jumbo/components/JumboTheme';
import AppRoutes from './AppRoutes';
import configureStore, { history } from './redux/store';
import { AppProvider } from './AppProvider';
import { config } from './config/main';
import { CircularProgress } from '@mui/material';
import { JumboAuthProvider } from '@jumbo/components/JumboAuthProvider/JumboAuthProvider';
import { initializeGoogleAnalytics } from './utils/google-analytics';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const store = configureStore();

function App() {
  const GOOGLE_OAUTH_2_CLIENT_ID = config.env.GOOGLE_OAUTH_2_CLIENT_ID ?? '';
  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/* `history` isn't a valid prop here, but I'm hesitant to remove it until I know more */}
        {/* @ts-ignore */}
        <BrowserRouter history={history}>
          <AppProvider>
            <JumboApp>
              <JumboTheme init={config.theme}>
                <GoogleOAuthProvider clientId={GOOGLE_OAUTH_2_CLIENT_ID}>
                  {/* Giving a TS error but will be replaced with shadcn */}
                  {/* @ts-ignore */}
                  <JumboAuthProvider>
                    <AppLayout activeLayout={config.defaultLayout}>
                      <Toaster />
                      <Suspense
                        fallback={
                          <div className="flex h-full min-w-0 content-center items-center">
                            <CircularProgress className="mx-auto -mt-10 text-accent-foreground" />
                          </div>
                        }
                      >
                        <AppRoutes />
                      </Suspense>
                    </AppLayout>
                  </JumboAuthProvider>
                </GoogleOAuthProvider>
              </JumboTheme>
            </JumboApp>
          </AppProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
