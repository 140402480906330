import { useEffect, useRef } from 'react';
import { ResizablePanelGroup, ResizablePanel, ScrollArea } from '@shadcn/ui';
import { Footer } from 'app/layouts/shared/footer';
import { Header } from 'app/layouts/shared/header';
import { Sidebar } from 'app/layouts/shared/sidebar';
import { useAppLayout } from 'app/utils/AppLayout';

// Smooth easing function
const easeOutCubic = (t: number) => 1 - Math.pow(1 - t, 3);

const COLLAPSED_PCT = 0;
const EXPANDED_PCT = 14;
const ANIM_DURATION = 300;

export const VerticalDefault = ({ children }) => {
  const { sidebarOpen } = useAppLayout();
  const sidebarPanelRef = useRef<any>(null);
  const intervalRef = useRef<number>(0);

  useEffect(() => {
    if (!sidebarPanelRef.current) {
      return;
    }

    // Use declarative Resizeable components API to animate the sidebar when `sidebarOpen` changes
    let currentPct = sidebarPanelRef.current.getSize();
    const targetPct = sidebarOpen ? EXPANDED_PCT : COLLAPSED_PCT;
    let start = 0;

    const step = (timestamp: number) => {
      if (!start) {
        start = timestamp;
      }

      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / ANIM_DURATION, 1);

      // Calculate new percentage using easing
      const newPct = currentPct + (targetPct - currentPct) * easeOutCubic(progress);

      sidebarPanelRef.current.resize(newPct);

      if (progress < 1) {
        intervalRef.current = window.requestAnimationFrame(step);
      } else {
        // Ensure we land exactly on target
        sidebarPanelRef.current.resize(targetPct);
        window.cancelAnimationFrame(intervalRef.current);
      }
    };

    intervalRef.current = window.requestAnimationFrame(step);

    return () => {
      window.cancelAnimationFrame(intervalRef.current);
    };
  }, [sidebarOpen]);

  return (
    <div className="grid h-screen w-screen grid-cols-1 grid-rows-[1fr_auto]">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          ref={sidebarPanelRef}
          collapsible
          collapsedSize={COLLAPSED_PCT}
          minSize={COLLAPSED_PCT}
          defaultSize={EXPANDED_PCT}
        >
          <Sidebar />
        </ResizablePanel>
        <ResizablePanel defaultSize={100 - EXPANDED_PCT}>
          <Header />
          <ScrollArea className="h-full w-full">{children}</ScrollArea>
        </ResizablePanel>
      </ResizablePanelGroup>
      <Footer />
    </div>
  );
};
