import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  useTheme,
  CircularProgress,
} from '@mui/material';
import {
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  InsertDriveFile as FileIcon,
  Description as DescriptionIcon,
  PictureAsPdf as PdfIcon,
  Image as ImageIcon,
  TableChart as SpreadsheetIcon,
} from '@mui/icons-material';

import { DocumentStepper } from './DocumentStepper';
import documentServices from 'app/services/document-services';
import { extractError } from 'app/utils/appHelpers';

export interface Document {
  _id: string;
  attributes: {
    fileName: string;
    documentType: string;
    status: string;
    createdAt: string;
    fileSize: number;
    detectedData?: Record<string, any>;
  };
}

const getFileIcon = (fileType) => {
  switch (fileType.toLowerCase()) {
    case 'pdf':
      return <PdfIcon color="error" />;
    case 'image':
      return <ImageIcon color="primary" />;
    case 'spreadsheet':
      return <SpreadsheetIcon color="success" />;
    case 'document':
      return <DescriptionIcon color="info" />;
    default:
      return <FileIcon />;
  }
};

const FileCard = ({
  file,
  onDelete,
  isProjectFile,
}: {
  file: Document;
  onDelete: (id: string) => void;
  isProjectFile: boolean;
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(file._id);
    handleMenuClose();
  };

  const handleNavigateToDocument = () => {
    navigate(`/dashboard/document/${file._id}`);
  };

  return (
    <Card
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        transition: 'all 0.3s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: 3,
          cursor: 'pointer',
        },
        border: `1px solid ${theme.palette.primary.contrastText}}`,
      }}
      onClick={handleNavigateToDocument}
    >
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          {getFileIcon(file.attributes.documentType || 'pdf')}
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleMenuOpen(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Typography>{file.attributes.status}</Typography>
        </Box>
        <Typography variant="h6" component="div" noWrap title={file.attributes.fileName}>
          {file.attributes.fileName}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          {new Date(file.attributes.createdAt).toLocaleDateString()}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, mt: 'auto', flexWrap: 'wrap' }}>
          {file.attributes.detectedData &&
            Object.keys(file.attributes.detectedData).map((key) => (
              <Chip key={key} label={key} size="small" variant="outlined" />
            ))}
        </Box>
      </CardContent>
      <CardActions>
        {isProjectFile && (
          <Chip
            label={`Size: ${(file.attributes.fileSize / 1024 / 1024).toFixed(2)} MB`}
            size="small"
            color="primary"
            sx={{ ml: 'auto' }}
          />
        )}
      </CardActions>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} onClick={(e) => e.stopPropagation()}>
        <MenuItem onClick={handleNavigateToDocument}>View Details</MenuItem>
        {isProjectFile && (
          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            Remove from Project
          </MenuItem>
        )}
      </Menu>
    </Card>
  );
};

export const Documents = ({ projectId, onSnackbar }) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const fetchDocuments = useCallback(async () => {
    try {
      const docs = await documentServices.getByProjectId(projectId);
      return docs;
    } catch (error) {
      console.log({ error });
      onSnackbar('Failed to fetch documents', 'error');
    }
  }, [projectId, onSnackbar]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    fetchDocuments().then((docs) => {
      if (isMounted && docs) {
        setDocuments(docs);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [fetchDocuments, refreshTrigger]);

  const handleDocumentDelete = async (fileId: string) => {
    setIsLoading(true);

    const msg = { success: 'Documents deleted successfully.', fail: 'Failed to delete document.' };
    try {
      const result = await documentServices.delete(fileId);
      if (result && result.status === 204) {
        onSnackbar(msg.success, 'success');
        setRefreshTrigger((prev) => prev + 1);
        return { success: true };
      } else {
        throw new Error(msg.fail);
      }
    } catch (error) {
      onSnackbar(extractError(error) || msg.fail, 'error');
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDocument = async (files) => {
    setIsLoading(true);

    try {
      const results = await documentServices.create({ files: files, projectId });
      onSnackbar('Documents created succesfully.', 'success');
      setRefreshTrigger((prev) => prev + 1);
      return results;
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to create documents.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsUploadDialogOpen(false);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', mt: 4, px: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1" fontWeight="bold">
          Project Documents
        </Typography>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setIsUploadDialogOpen(true)}>
          Add Document
        </Button>
      </Box>

      <Typography variant="h5" className="mb-2">
        Project Files
      </Typography>
      <Grid container spacing={3}>
        {documents.map((file: Document) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={file._id}>
            <FileCard file={file} onDelete={handleDocumentDelete} isProjectFile={true} />
          </Grid>
        ))}
      </Grid>

      <Dialog open={isUploadDialogOpen} onClose={() => setIsUploadDialogOpen(false)} fullWidth maxWidth="md">
        <DocumentStepper onDocumentCreate={handleCreateDocument} onClose={handleModalClose} />
      </Dialog>
    </Box>
  );
};
