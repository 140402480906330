import jwtAuthAxios from './auth/jwtAuth';

export const apiKeyServices = {
  create: async (params) => {
    const { data } = await jwtAuthAxios.post('/v1/tokens', params);
    return data;
  },
  get: async () => {
    const { data } = await jwtAuthAxios.get('/v1/tokens');
    return data;
  },
  delete: async (id) => {
    const data = await jwtAuthAxios.delete(`/v1/tokens/${id}`);
    return data;
  },
};
