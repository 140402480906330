import { useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  LinearProgress,
} from '@mui/material';
import { Close as CloseIcon, CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import * as pdfjsLib from 'pdfjs-dist';
import '../../../../../node_modules/pdfjs-dist/build/pdf.worker.min.mjs';
import PDFViewer from './PDFViewer';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const steps = ['Upload PDFs', 'Enter Document Info', 'Mark Bill Breaks', 'Review & Process'];
const MAX_FILE_SIZE_BYTES = 2000000000; // 2GB

export const DocumentStepper = ({ onClose, onDocumentCreate }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const [utilityProvider, setUtilityProvider] = useState('');
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [billBreaks, setBillBreaks] = useState({});
  const [error, setError] = useState<null | string>(null);

  const filesWithMultipleBills = useMemo(() => files.filter((file) => file.hasMultipleBills), [files]);

  const currentMultiBillFileIndex = useMemo(() => {
    const index = filesWithMultipleBills.findIndex((file) => file.fileName === files[currentFileIndex]?.fileName);
    return index >= 0 ? index : 0;
  }, [filesWithMultipleBills, files, currentFileIndex]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const processedFiles = await Promise.all(
      acceptedFiles.map(async (file) => {
        if (file.size > MAX_FILE_SIZE_BYTES) {
          setError(`File ${file.name} exceeds the maximum size limit of 2GB.`);
          return null;
        }
        const fileBuffer = await file.arrayBuffer();
        const typedArray = new Uint8Array(fileBuffer);
        const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
        return {
          file,
          fileName: file.name,
          mimeType: file.type,
          fileSize: file.size,
          lastModified: file.lastModified,
          numberOfPages: pdf.numPages,
          hasMultipleBills: false,
        };
      })
    );
    setFiles((prevFiles) => [...prevFiles, ...processedFiles.filter((file) => file !== null)]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
    multiple: true,
    maxSize: MAX_FILE_SIZE_BYTES,
  });

  const handleNext = () => {
    if (activeStep === 1) {
      if (filesWithMultipleBills.length > 0) {
        setActiveStep(2); // Go to Mark Bill Breaks
        setCurrentFileIndex(files.findIndex((file) => file.fileName === filesWithMultipleBills[0].fileName));
      } else {
        setActiveStep(3); // Skip to Review & Process
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 3 && filesWithMultipleBills.length === 0) {
      setActiveStep(1); // Go back to Enter Document Info
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleMultipleBillsChange = (index) => {
    setFiles((prevFiles) =>
      prevFiles.map((file, i) => (i === index ? { ...file, hasMultipleBills: !file.hasMultipleBills } : file))
    );
    if (!files[index].hasMultipleBills) {
      setBillBreaks((prev) => {
        const newBreaks = { ...prev };
        delete newBreaks[files[index].fileName];
        return newBreaks;
      });
    } else if (filesWithMultipleBills.length === 0) {
      setCurrentFileIndex(index);
    }
  };

  const handleFileChange = (event) => {
    const selectedMultiBillIndex = event.target.value;
    const selectedFile = filesWithMultipleBills[selectedMultiBillIndex];
    const newIndex = files.findIndex((file) => file.fileName === selectedFile.fileName);
    setCurrentFileIndex(newIndex);
  };

  const handleMarkBillBreak = useCallback(
    (pageNum) => {
      setBillBreaks((prev) => {
        const currentBreaks = prev[files[currentFileIndex].fileName] || [];
        return {
          ...prev,
          [files[currentFileIndex].fileName]: [...new Set<number>([...currentBreaks, pageNum])].sort((a, b) => a - b),
        };
      });
    },
    [currentFileIndex, files]
  );

  const handleRemoveBillBreak = useCallback(
    (pageNum) => {
      setBillBreaks((prev) => {
        const currentBreaks = prev[files[currentFileIndex].fileName] || [];
        return {
          ...prev,
          [files[currentFileIndex].fileName]: currentBreaks.filter((num) => num !== pageNum),
        };
      });
    },
    [currentFileIndex, files]
  );

  const handleUtilityProviderChange = (event) => {
    setUtilityProvider(event.target.value);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setBillBreaks((prev) => {
      const newBreaks = { ...prev };
      delete newBreaks[files[index].fileName];
      return newBreaks;
    });
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError('No documents to upload. You must select a document to submit.');
      return;
    }

    if (!utilityProvider) {
      setError('You must select a utility provider.');
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      const results = await onDocumentCreate(files);
      const uploadPromises = results.map(async (doc) => {
        const {
          attributes: { uploadUrl, fileName },
        } = doc;
        const file = files.find((f) => f.fileName === fileName);
        const requestOptions = {
          headers: { 'Content-Type': file.mimeType },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress((prev) => ({ ...prev, [fileName]: percentCompleted }));
          },
        };
        return axios.put(uploadUrl, file.file, requestOptions);
      });

      await Promise.all(uploadPromises);
    } catch (error) {
      setError(`Upload failed: ${error instanceof Error ? error.message : 'Unknown error.'}`);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Paper
              {...getRootProps()}
              sx={{
                p: 3,
                textAlign: 'center',
                cursor: 'pointer',
                bgcolor: isDragActive ? 'action.hover' : 'background.paper',
              }}
            >
              <input {...getInputProps()} />
              <CloudUploadIcon sx={{ fontSize: 48, mb: 2 }} />
              <Typography variant="h6">
                {isDragActive ? 'Drop the PDFs here' : "Drag 'n' drop PDFs here, or click to select"}
              </Typography>
            </Paper>
            {files.length > 0 && (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>File Name</TableCell>
                      <TableCell align="right">Size</TableCell>
                      <TableCell align="right">Pages</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((file, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {file.fileName}
                        </TableCell>
                        <TableCell align="right">{(file.fileSize / 1024 / 1024).toFixed(2)} MB</TableCell>
                        <TableCell align="right">{file.numberOfPages}</TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => handleRemoveFile(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        );
      case 1:
        return (
          <Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>Utility Provider</InputLabel>
              <Select value={utilityProvider} onChange={handleUtilityProviderChange}>
                <MenuItem value="con_edison">Con Edison</MenuItem>
                <MenuItem value="psg">Public Service Electric and Gas (PSG)</MenuItem>
                <MenuItem value="national_grid">National Grid</MenuItem>
              </Select>
            </FormControl>
            {files.length > 0 && (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>File Name</TableCell>
                      <TableCell align="right">Multiple Bills?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((file, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {file.fileName}
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox checked={file.hasMultipleBills} onChange={() => handleMultipleBillsChange(index)} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        );
      case 2:
        return (
          <Box>
            {filesWithMultipleBills.length > 0 ? (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Select File</InputLabel>
                  <Select value={currentMultiBillFileIndex} onChange={handleFileChange}>
                    {filesWithMultipleBills.map((file, index) => (
                      <MenuItem key={file.fileName} value={index}>
                        {file.fileName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {files[currentFileIndex] && files[currentFileIndex].hasMultipleBills && (
                  <PDFViewer
                    key={files[currentFileIndex].fileName}
                    file={files[currentFileIndex]}
                    onMarkBillBreak={handleMarkBillBreak}
                    onRemoveBillBreak={handleRemoveBillBreak}
                    billBreaks={billBreaks[files[currentFileIndex].fileName] || []}
                  />
                )}
              </>
            ) : (
              <Typography>No files with multiple bills. You can proceed to the next step.</Typography>
            )}
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Review Your Upload
            </Typography>
            <Typography>Utility Provider: {utilityProvider}</Typography>
            <Typography>Number of Files: {files.length}</Typography>
            {files.map((file, index) => (
              <Box key={index} sx={{ mt: 2 }}>
                <Typography variant="subtitle1">{file.fileName}</Typography>
                <Typography variant="body2">Size: {(file.fileSize / 1024 / 1024).toFixed(2)} MB</Typography>
                <Typography variant="body2">Pages: {file.numberOfPages}</Typography>
                <Typography variant="body2">Multiple Bills: {file.hasMultipleBills ? 'Yes' : 'No'}</Typography>
                {file.hasMultipleBills && (
                  <Typography variant="body2">
                    Bill Breaks: {billBreaks[file.fileName]?.join(', ') || 'None'}
                  </Typography>
                )}
                {isUploading && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[file.fileName] || 0}
                      sx={{ flexGrow: 1, mr: 1 }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {uploadProgress[file.fileName] || 0}%
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
            {!isUploading && (
              <Button variant="contained" color="primary" onClick={handleUpload} sx={{ mt: 2 }}>
                Upload Documents
              </Button>
            )}
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleCloseModal}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: '80vh',
          maxHeight: '900px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle>Upload Documents</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Stepper activeStep={activeStep} sx={{ py: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ flex: 1, overflow: 'auto', mb: 2 }}>{renderStepContent(activeStep)}</Box>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep < steps.length - 1 ? (
          <Button onClick={handleNext} disabled={files.length === 0 || (activeStep === 1 && !utilityProvider)}>
            Next
          </Button>
        ) : (
          <Button onClick={handleCloseModal} color="primary">
            Done
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
