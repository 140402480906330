import React from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { ShowSecretKeyModal } from './ShowSecretKeyContent';

export const CreateSecretKeyModal = ({
  apiKeyName,
  open,
  handleInputChange,
  handleClose,
  handleCreateKey,
  showSecret,
  secretKey,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      {showSecret ? (
        <ShowSecretKeyModal handleClose={handleClose} secretKey={secretKey} />
      ) : (
        <Container>
          <DialogTitle>Create new API key</DialogTitle>
          <DialogContent sx={{ minWidth: '400px' }}>
            <TextField
              autoFocus
              margin="dense"
              label="Incredible Hulk key"
              type="text"
              fullWidth
              placeholder="incredible hulk key"
              value={apiKeyName}
              onChange={handleInputChange}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ backgroundColor: 'transparent' }} color="inherit">
              Cancel
            </Button>
            <Button onClick={handleCreateKey} color="info">
              Create
            </Button>
          </DialogActions>
        </Container>
      )}
    </Dialog>
  );
};
