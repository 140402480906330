export const SIDEBAR_VARIANTS = {
  PERSISTENT: 'persistent',
  TEMPORARY: 'temporary',
  PERMANENT: 'permanent',
};

export const SIDEBAR_STYLES = {
  FULL_HEIGHT: 'full-height',
  CLIPPED_UNDER_HEADER: 'clipped-under-header',
};

export const SIDEBAR_VIEWS = {
  MINI: 'mini',
  FULL: 'full',
};

export const SIDEBAR_SCROLL_TYPES = {
  DEFAULT: 'default',
  FIXED: 'fixed',
};

export const SIDEBAR_ANCHOR_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const LAYOUT_CONTAINER_STYLES = {
  FLUID: 'fluid',
  BOXED: 'boxed',
};
