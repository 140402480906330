import { MouseEvent, useCallback, useEffect, useState } from 'react';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import { PopoverProps } from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';

import reportServices from 'app/services/report-services';
import { extractError } from 'app/utils/appHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // @ts-ignore
    padding: theme.spacing(3),
  },
  paper: {
    // @ts-ignore
    padding: theme.spacing(3),
    // @ts-ignore
    marginBottom: theme.spacing(3),
  },
  form: {
    '& .MuiTextField-root': {
      // @ts-ignore
      marginBottom: theme.spacing(2),
    },
  },
  submitButton: {
    // @ts-ignore
    marginTop: theme.spacing(2),
  },
  card: {
    // @ts-ignore
    border: `1px solid ${theme.palette.text.primary}`,
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    },
    minHeight: '200px',
  },
}));

type Report = {
  id: string;
  attributes: {
    name: string;
    status: string;
    createdAt: string;
  };
};

interface ReportsProps {
  projectId: string;
  onSnackbar: (message: string, severity: AlertColor) => void;
}

export const Reports = ({ projectId, onSnackbar }: ReportsProps) => {
  const classes = useStyles();
  const [reports, setReports] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openNewReportDialog, setOpenNewReportDialog] = useState(false);
  const [newReportName, setNewReportName] = useState('');
  const [newOutputType, setNewOutputType] = useState('');
  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>(null);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const fetchReports = useCallback(async () => {
    try {
      return await reportServices.getByProjectId(projectId);
    } catch (error) {
      onSnackbar('An unexpected error occurred while fetching the reports', 'error');
    }
  }, [projectId, onSnackbar]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    fetchReports().then((fetchedReports) => {
      if (isMounted) {
        setReports(fetchedReports);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [fetchReports, refreshTrigger]);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>, report: Report) => {
    setAnchorEl(event.currentTarget);
    setSelectedReport(report);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedReport(null);
  };

  const downloadFile = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        onSnackbar(error.message || 'Failed to download report', 'error');
      });
  };

  const handleDownload = async (report: Report) => {
    const { id: reportId } = report;
    try {
      const downloadUrl = await reportServices.getDownloadLink(reportId);
      downloadFile(downloadUrl, reportId);
      handleCloseMenu();
    } catch (e) {
      onSnackbar('Failed to downlaod report.', 'error');
    }
  };

  const handleDeleteReport = async () => {
    if (!selectedReport) {
      return;
    }
    setIsSubmitting(true);

    try {
      const { status } = await reportServices.delete(selectedReport.id);
      if (status === 204) {
        onSnackbar('Report deleted successfully', 'success');
        setRefreshTrigger((prev) => prev + 1);
        handleCloseMenu();
      } else {
        throw new Error('Failed to delete the report');
      }
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to delete the report', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreateReport = async () => {
    if (validateForm()) {
      setIsSubmitting(true);

      try {
        const report = await reportServices.create({
          project: projectId,
          name: newReportName.trim(),
          outputType: newOutputType,
        });

        handleCloseNewReportDialog();
        onSnackbar('Report created succesfully.', 'success');
        setRefreshTrigger((prev) => prev + 1);
        return report;
      } catch (error) {
        onSnackbar('Error generating report', 'error');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      onSnackbar('Please correct the errors before submitting', 'error');
    }
  };

  const handleOpenNewReportDialog = () => {
    setOpenNewReportDialog(true);
  };

  const handleCloseNewReportDialog = () => {
    setOpenNewReportDialog(false);
    setNewReportName('');
    setNewOutputType('');
    setErrors({});
  };

  const validateForm = () => {
    const tempErrors = {
      newReportName: newReportName.trim() ? '' : 'Report name is required',
      newOutputType: newOutputType ? '' : 'Output type is required',
    };

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'success';
      case 'in progress':
        return 'warning';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h4" component="h1">
            Reports
          </Typography>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenNewReportDialog}>
            New Report
          </Button>
        </Box>

        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={400}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            {reports.map((report) => (
              <Grid item xs={12} sm={6} md={4} key={report.id}>
                <Card className={classes.card}>
                  <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="h6" component="h2">
                        {report.attributes.name}
                      </Typography>
                      <IconButton size="small" onClick={(e) => handleOpenMenu(e, report)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                    <Typography color="textSecondary" variant="body2" gutterBottom>
                      Created: {format(new Date(report.attributes.createdAt), 'MMM d, yyyy HH:mm')}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                      <Chip
                        label={report.attributes.status.toUpperCase()}
                        size="small"
                        color={getStatusColor(report.attributes.status)}
                      />
                    </Box>
                  </CardContent>
                  <CardActions>
                    {report.attributes.status === 'completed' && (
                      <Tooltip title="Download Report">
                        <IconButton onClick={() => handleDownload(report)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
          <MenuItem onClick={handleDeleteReport}>
            <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>

        <Dialog open={openNewReportDialog} onClose={handleCloseNewReportDialog}>
          <DialogTitle>Generate New Report</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Report Name"
              type="text"
              fullWidth
              value={newReportName}
              onChange={(e) => setNewReportName(e.target.value)}
              error={!!errors.newReportName}
              helperText={errors.newReportName}
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="output-type-label">Output Type</InputLabel>
              <Select
                labelId="output-type-label"
                value={newOutputType}
                onChange={(e) => setNewOutputType(e.target.value)}
                label="Output Type"
                error={!!errors.newOutputType}
              >
                <MenuItem value="xlsx">XLSX</MenuItem>
              </Select>
              {errors.newOutputType && (
                <Typography color="error" variant="caption">
                  {errors.newOutputType}
                </Typography>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewReportDialog}>Cancel</Button>
            <Button onClick={handleCreateReport} variant="contained" color="primary" disabled={isSubmitting}>
              {isSubmitting ? 'Generating...' : 'Generate'}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
};
