import { isAxiosError } from 'app/utils/appHelpers';
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import { AuthPageShell } from 'app/pages/auth-pages/AuthPageShell';
import authServices from 'app/services/auth-services';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

export const ResetPassword = () => {
  const [serverMessage, setServerMessage] = useState('');

  const onSubmit = async (email) => {
    try {
      await authServices.forgotPassword(email);
      setServerMessage('Reset link sent. Please check your email! (Note that this may take a few minutes.)');
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data?.errors;
        const errorObject = errors[0];
        const message = errorObject?.detail || 'Something went wrong. Please try again!';
        setServerMessage(message);
      }
    }
  };

  const renderForm = () => (
    <Div
      sx={{
        display: 'flex',
        minWidth: 0,
        flex: 1,
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Formik
        validateOnChange={true}
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(data.email);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <Div sx={{ mt: 1, mb: 2 }}>
              <JumboTextField fullWidth name="email" label="Email" type="email" />
            </Div>
            {serverMessage && (
              <Div sx={{ mb: 2 }}>
                <Typography>{serverMessage}</Typography>
              </Div>
            )}
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{ mb: 3 }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </Form>
        )}
      </Formik>
      <Typography variant={'h5'} align={'center'} mb={1}>
        Enter your account email. We'll send you instructions to reset your password.
      </Typography>
    </Div>
  );

  return <AuthPageShell rightContent={renderForm()} />;
};
