import TokenIcon from '@mui/icons-material/Token';
import UploadOutlined from '@mui/icons-material/UploadOutlined';
import { InsertInvitationOutlined } from '@mui/icons-material';

const plans = {
  basic: [
    {
      text: '3 Edits per month (Trim, Clip, Convert to GIF)',
      icon: <TokenIcon fontSize={'small'} />,
    },
    {
      text: '6 Uploads per month',
      icon: <UploadOutlined />,
    },
  ],
  pro: [
    {
      text: '30 Edits per month (Trim, Clip, Convert to GIF)',
      icon: <TokenIcon fontSize={'small'} />,
    },
    {
      text: '60 Uploads per month',
      icon: <UploadOutlined />,
    },
    {
      text: 'Invite 1 user to collaborate, $14/user',
      icon: <InsertInvitationOutlined />,
    },
  ],
  enterprise: [
    {
      text: '180 Edits per month (Trim, Clip, Convert to GIF)',
      icon: <TokenIcon fontSize={'small'} />,
    },
    {
      text: '360 Uploads per month',
      icon: <UploadOutlined />,
    },
    {
      text: 'Invite unlimited users to collaborate, $20/user',
      icon: <InsertInvitationOutlined />,
    },
  ],
};

const featuresServices = {
  getFeaturesList: (plan: keyof typeof plans) => {
    return plans[plan];
  },
};

export default featuresServices;
