import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import List from '@mui/material/List';

const FeaturesList = ({ features }) => {
  const renderFeatures = (featureList) => {
    return featureList.map((f) => {
      return (
        <ListItem key={f.text}>
          <ListItemIcon sx={{ minWidth: 32, color: 'inherit' }}>{f.icon}</ListItemIcon>
          <ListItemText primary={f.text} />
        </ListItem>
      );
    });
  };

  return (
    <List disablePadding sx={{ mb: 4 }}>
      {renderFeatures(features)}
    </List>
  );
};

export default FeaturesList;
