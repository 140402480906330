import { PropsWithChildren } from 'react';
import { ReactElement } from 'react';

import { VerticalDefault } from 'app/layouts/vertical-default/VerticalDefault';
import { SoloPage } from 'app/layouts/solo-page/SoloPage';

const LAYOUT_NAMES = {
  VERTICAL_DEFAULT: 'vertical-default',
  SOLO_PAGE: 'solo-page',
};

const LAYOUTS: { name: string; label: string; component: ({ children }: PropsWithChildren<any>) => ReactElement }[] = [
  {
    name: LAYOUT_NAMES.VERTICAL_DEFAULT,
    label: 'Vertical Default',
    component: VerticalDefault,
  },
  {
    name: LAYOUT_NAMES.SOLO_PAGE,
    label: 'Solo Page',
    component: SoloPage,
  },
];

export { LAYOUTS, LAYOUT_NAMES };
