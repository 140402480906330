import { extractError } from 'app/utils/appHelpers';
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CreateSecretKeyModal } from './CreateSecretKeyModal';
import { apiKeyServices } from 'app/services/api-key-services';
import DeleteIcon from '@mui/icons-material/Delete';
import { useJumboTheme } from '@jumbo/hooks';

const useStyles = makeStyles({
  header: {
    fontSize: '1.15rem',
    border: `1px solid #7352C7`,
    fontWeight: 'bold',
  },
  bodyCell: {
    fontSize: '1rem',
    border: `1px solid #7352C7`,
  },
});

export const APIKeys = () => {
  const classes = useStyles();
  // @ts-ignore
  const { theme } = useJumboTheme();
  const [openModal, setOpenModal] = useState(false);
  const [apiKeyName, setApiKeyName] = useState('');
  const [apiKeys, setApiKeys] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const [error, setError] = useState('');

  const handleClose = () => {
    setOpenModal(false);
    setShowSecret(false);
    setSecretKey('');
    setApiKeyName('');
    setRefreshTable((prev) => !prev);
  };

  const handleInputChange = (event) => {
    const {
      target: { value },
    } = event;

    setApiKeyName(value);
  };

  const handleCreateKey = async () => {
    if (!apiKeyName) {
      return;
    }

    const params = { name: apiKeyName };
    try {
      const tokenResult = await apiKeyServices.create(params);
      if (tokenResult) {
        const {
          attributes: { key },
        } = tokenResult;
        setShowSecret(true);
        setSecretKey(key);
      }
    } catch (err) {
      setError(extractError(err) ?? 'An error occurred creating the API key');
    }
  };

  const handleDeleteKey = async (id) => {
    try {
      const { status } = await apiKeyServices.delete(id);
      if (status === 204) {
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      setError(extractError(err) ?? 'An error occurred deleting the API key');
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const res = await apiKeyServices.get();
        if (isMounted) {
          setApiKeys(res);
        }
      } catch (err) {
        setError(extractError(err) ?? 'An error occurred fetching the API key');
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [refreshTable]);

  return (
    <Grid sx={{ alignSelf: 'center', padding: '30px 0', height: '50%', width: '90%', minHeight: '400px' }}>
      <Button
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          marginBottom: '30px',
          border: `1px solid ${theme.palette.primary.main}`,
        }}
        onClick={() => setOpenModal(true)}
      >
        Create a new API key
      </Button>
      {error && <p>{error}</p>}
      <TableContainer component={Paper}>
        <Table sx={{ border: `1px solid ${theme.palette.primary.main}` }}>
          <TableHead>
            <TableRow sx={{ border: `1px solid ${theme.palette.primary.main}` }}>
              <TableCell className={classes.header}>Name</TableCell>
              <TableCell className={classes.header}>Token</TableCell>
              <TableCell className={classes.header}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys &&
              apiKeys.map((apiKey) => {
                const {
                  attributes: { name, key },
                  id,
                } = apiKey;
                return (
                  <TableRow
                    key={id}
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      '&:hover': { backgroundColor: theme.palette.primary.main },
                    }}
                  >
                    <TableCell className={classes.bodyCell}>{name}</TableCell>
                    <TableCell className={classes.bodyCell}>{`****${key}`}</TableCell>
                    <TableCell className={classes.bodyCell}>
                      <DeleteIcon onClick={() => handleDeleteKey(id)} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateSecretKeyModal
        open={openModal}
        apiKeyName={apiKeyName}
        handleInputChange={handleInputChange}
        handleCreateKey={handleCreateKey}
        handleClose={handleClose}
        showSecret={showSecret}
        secretKey={secretKey}
      />
    </Grid>
  );
};
