import { Fragment } from 'react';

import routes from './routes';
import useJumboRoutes from '@jumbo/hooks/useJumboRoutes';

const AppRoutes = () => {
  const appRoutes = useJumboRoutes(routes);

  return <Fragment>{appRoutes}</Fragment>;
};
export default AppRoutes;
