import { useToast } from '@shadcn/hooks/use-toast';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import { makeStyles } from '@mui/styles';

import projectServices from 'app/services/project-services';
import { extractError } from 'app/utils/appHelpers';

import { BuildingInfo } from './tabs/BuildingInfo';
import { Documents } from './tabs/Documents';
import { Reports } from './tabs/Reports';
import { Visualizations, WeatherProps } from './tabs/Visualizations';
import EstimatedPenalties from './tabs/EstimatedPenalties';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // @ts-ignore
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
  header: {
    // @ts-ignore
    padding: theme.spacing(2),
    // @ts-ignore
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    flexGrow: 1,
    // @ts-ignore
    padding: theme.spacing(3),
  },
  contentWrapper: {
    animation: '$fadeInUp 0.5s ease-out',
  },
  '@keyframes fadeInUp': {
    from: {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

export type ProjectDetailData = {
  attributes: {
    weather: WeatherProps;
  };
  id: string;
};

export const ProjectDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [project, setProject] = useState<ProjectDetailData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const { toast } = useToast();

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchProject = async () => {
      try {
        return await projectServices.getById(id);
      } catch (error) {
        console.error('Error fetching project:', error);
        return null;
      }
    };

    fetchProject().then((fetchedProject) => {
      if (isMounted) {
        setProject(fetchedProject);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [id, refreshTrigger]);

  if (isLoading || !id) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const handleSnackbar = (message: string, severity: AlertColor = 'success') => {
    toast({ description: message, variant: severity === 'error' ? 'destructive' : 'default' });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleUpdateProject = async (updatedProject) => {
    try {
      const result = await projectServices.update(updatedProject);
      if (result) {
        setProject(result);
        handleSnackbar('Project updated successfully', 'success');
        setRefreshTrigger((prev) => prev + 1);
        return { success: true, message: 'Project updated successfully', report: result };
      } else {
        throw new Error(result?.message || 'Failed to update project.');
      }
    } catch (error) {
      handleSnackbar(extractError(error) ?? 'An error occurred while updating the project', 'error');
      return {
        success: false,
        message: extractError(error) || 'An error occurred while updating the project',
      };
    }
  };

  // TODO: move handleUpdate project to BuildingInfo
  const renderContent = () => {
    let content;
    switch (activeTab) {
      case 0:
        content = <BuildingInfo project={project} onUpdateProject={handleUpdateProject} onSnackbar={handleSnackbar} />;
        break;
      case 1:
        content = project ? <Documents projectId={id} onSnackbar={handleSnackbar} /> : null;
        break;
      case 2:
        content = project ? <Reports projectId={id} onSnackbar={handleSnackbar} /> : null;
        break;
      case 3:
        content = project ? <Visualizations projectId={id} onSnackbar={handleSnackbar} /> : null;
        break;
      case 4:
        content = project ? <EstimatedPenalties projectId={id} /> : null;
        break;
      default:
        content = <Typography>No content available</Typography>;
    }

    return (
      <div className={classes.contentWrapper} key={activeTab}>
        {content}
      </div>
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="project navigation">
          <Tab label="Building Info" />
          <Tab label="Documents" />
          <Tab label="Reports" />
          <Tab label="Insights" />
          <Tab label="Estimated Penalties" />
        </Tabs>
      </Box>
      <Box className={classes.content}>{renderContent()}</Box>
    </Box>
  );
};
