import { LAYOUT_CONTAINER_STYLES } from '@jumbo/utils/constants/layout';
import { createJumboTheme } from '@jumbo/utils';

import { mainTheme } from '../themes/main/dark';
import { headerTheme } from '../themes/header/dark';
import { sidebarTheme } from '../themes/sidebar/dark';
import { footerTheme } from '../themes/footer/dark';
import { LAYOUT_NAMES } from '../layouts/layouts';
import jwtAuthAxios from '../services/auth/jwtAuth';
import authServices from '../services/auth-services';

import * as env from './env';

const config = {
  defaultLayout: LAYOUT_NAMES.SOLO_PAGE,
  containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,
  theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),
  authSetting: {
    axiosObject: jwtAuthAxios,
    fallbackPath: '/user/login',
    getAuthUserService: authServices.getCurrentUser,
    redirectNotAuthenticatedPath: '/dashboard/project',
  },
  env,
};

export { config };
