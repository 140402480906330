import { StripeLinkAuthenticationElementChangeEvent } from '@stripe/stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js/types/stripe-js/elements/payment';
import React, { useState } from 'react';
import { PaymentElement, LinkAuthenticationElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CircularProgress } from '@mui/material';

const baseUrl = process.env.REACT_APP_CLIENT_BASE_URL || 'https://app.golivecosmos.com';

export const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${baseUrl}/account/billing`,
        receipt_email: email,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? 'Something went wrong. Please try again.');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form
      id="payment-form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      onSubmit={handleSubmit}
    >
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e: StripeLinkAuthenticationElementChangeEvent) =>
          setEmail(
            e?.value?.email ??
              // @ts-ignore - is this right?
              e?.target?.value
          )
        }
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {isLoading ? (
        <CircularProgress style={{ marginTop: '30px' }} />
      ) : (
        <button
          style={{
            backgroundColor: '#7352C7',
            padding: '5px 20px',
            margin: '30px 0 0 0',
            fontSize: '1.25rem',
            color: 'white',
            width: '250px',
          }}
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          Pay
        </button>
      )}

      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};
