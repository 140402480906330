import { isAxiosError } from 'app/utils/appHelpers';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { jwtDecode } from 'jwt-decode';
import { Form, Formik } from 'formik';

import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { AuthPageShell } from 'app/pages/auth-pages/AuthPageShell';
import authServices from 'app/services/auth-services';
import userServices from 'app/services/user-services';

const validationSchema = yup.object({ password: yup.string().required('Password is required') });

const typeToConfig = {
  default: {
    tokenLackingErrorMessage: 'Temporary token not found. Please try again!',
    userNotFoundErrorMessage: 'User not found with temporary token. Please try again!',
    welcomeMessage: 'Welcome to Cosmos! Set a password to finish setting up your Cosmos account.',
  },
};

export const SetPassword = () => {
  const { setAuthToken } = useJumboAuth();
  const { tempToken } = useParams();
  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState('');

  const config = typeToConfig.default;

  const onSubmit = async (password) => {
    if (!tempToken) {
      setServerMessage(config.tokenLackingErrorMessage);
      return;
    }

    try {
      const currentUser = await authServices.getUserByTempToken(tempToken);
      if (!currentUser) {
        setServerMessage(config.userNotFoundErrorMessage);
        return;
      }

      setAuthToken(tempToken);
      const { organizationId } = jwtDecode<{ organizationId: string }>(tempToken);

      // if the currentUser org does not match the token org then this is an existing user accepting an invite to an org.
      const reassignOrg = currentUser.relationships.organization.data.id !== organizationId;
      const updatedUser = await userServices.update({
        id: currentUser.id,
        data: { password, organization: organizationId, isTemporary: false, reassignOrg },
      });
      if (updatedUser?.attributes?.token) {
        setAuthToken(updatedUser?.attributes?.token);
        navigate('/');
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data?.errors;
        const errorObject = errors[0];
        const message = errorObject?.detail;
        setServerMessage(message);
      }
    }
  };

  const renderLeftContent = () => (
    <>
      <Typography variant={'h4'} color={'inherit'} fontWeight={500} mb={2}>
        Set Password
      </Typography>
      <Typography variant={'body1'} mb={1} sx={{ maxWidth: 270 }}>
        {config.welcomeMessage}
      </Typography>
    </>
  );

  const renderForm = () => (
    <Div sx={{ display: 'flex', minWidth: 0, flex: 1, flexDirection: 'column', height: '100%' }}>
      <Formik
        validateOnChange={true}
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(data.password);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <Div sx={{ mt: 1, mb: 2 }}>
              <JumboTextField fullWidth name="password" label="Password" type="password" />
            </Div>
            {serverMessage && (
              <Div sx={{ mb: 2 }}>
                <Typography>{serverMessage}</Typography>
              </Div>
            )}
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{ mb: 3 }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </Div>
  );

  return <AuthPageShell leftContent={renderLeftContent()} rightContent={renderForm()} />;
};
