import { FormEvent, useState, useEffect, useMemo } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  LinearProgress,
  Zoom,
  Fade,
  Collapse,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Edit as EditIcon,
  Cancel as CancelIcon,
  Home as HomeIcon,
  LocationCity as LocationCityIcon,
  PinDrop as PinDropIcon,
  SquareFoot as SquareFootIcon,
  Event as EventIcon,
  Info as InfoIcon,
  Bolt as BoltIcon,
} from '@mui/icons-material';
import projectServices from 'app/services/project-services';
import { BriefcaseBusinessIcon } from 'lucide-react';
import { UTILITY_SERIVCES } from 'app/utils/constants/utilityServices';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // @ts-ignore
    padding: theme.spacing(0, 3),
  },
  paper: {
    // @ts-ignore
    padding: theme.spacing(3),
    // @ts-ignore
    marginBottom: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
  },
  form: {
    '& .MuiTextField-root': {
      // @ts-ignore
      marginBottom: theme.spacing(2),
    },
  },
  submitButton: {
    // @ts-ignore
    marginTop: theme.spacing(2),
  },
  progressWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  cardWrapper: {
    // @ts-ignore
    marginBottom: theme.spacing(2),
    transition: 'transform 0.3s ease-in-out',
    // @ts-ignore
    border: `1px ${theme.palette.text.primary} solid`,
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  cardContent: {
    // @ts-ignore
    paddingBottom: theme.spacing(1),
  },
  infoIcon: {
    // @ts-ignore
    marginLeft: theme.spacing(1),
    fontSize: '1rem',
    // @ts-ignore
    color: theme.palette.text.secondary,
  },
  editingIndicator: {
    // @ts-ignore
    backgroundColor: theme.palette.primary.main,
    // @ts-ignore
    color: theme.palette.primary.contrastText,
    // @ts-ignore
    padding: theme.spacing(1, 2),
    // @ts-ignore
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    // @ts-ignore
    top: theme.spacing(1),
    // @ts-ignore
    right: theme.spacing(1),
    zIndex: 1,
  },
}));

type BuildingInfoData = {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  squareFeet: string;
  yearBuilt: string;
  services: string[];
  propertyType: string;
};

const blankBuildingInfo: BuildingInfoData = {
  name: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  squareFeet: '',
  yearBuilt: '',
  services: [],
  propertyType: '',
};

export const BuildingInfo = ({ project, onUpdateProject, onSnackbar }) => {
  const classes = useStyles();
  const [buildingInfo, setBuildingInfo] = useState<BuildingInfoData>(blankBuildingInfo);
  const [originalInfo, setOriginalInfo] = useState<BuildingInfoData>(blankBuildingInfo);
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [propertyTypeList, setPropertyTypeList] = useState<string[]>([]);

  useEffect(() => {
    if (project && project.attributes) {
      const projectInfo: BuildingInfoData = {
        name: project.attributes.name || '',
        address: project.attributes.address || '',
        city: project.attributes.city || '',
        state: project.attributes.state || '',
        zipCode: project.attributes.zipCode || '',
        squareFeet: project.attributes.squareFeet || '',
        yearBuilt: project.attributes.yearBuilt || '',
        services: project.attributes.services || [],
        propertyType: project.attributes.propertyType || '',
      };

      setBuildingInfo(projectInfo);
      setOriginalInfo(projectInfo);
      calculateProgress(projectInfo);
    }
  }, [project]);

  useEffect(() => {
    let isMounted = true;

    const fetchPropertyList = async () => {
      try {
        const propertyTypes = await projectServices.getPropertyTypes();
        if (isMounted) {
          setPropertyTypeList(propertyTypes);
        }
      } catch (error) {
        console.error('Failed to fetch property types:', error);
        onSnackbar('Failed to load property types', 'error');
      }
    };

    fetchPropertyList();

    return () => {
      isMounted = false;
    };
  }, [onSnackbar]);

  const calculateProgress = (info: BuildingInfoData) => {
    const totalFields = Object.keys(info).length;
    const filledFields = Object.entries(info).filter(([key, value]) => {
      if (key === 'services') {
        return value.length > 0;
      }
      return value !== '';
    }).length;
    setProgress((filledFields / totalFields) * 100);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedInfo = { ...buildingInfo, [name]: value };
    setBuildingInfo(updatedInfo);
    setErrors({ ...errors, [name]: '' });
    calculateProgress(updatedInfo);
  };

  const validateForm = () => {
    let tempErrors: Partial<Record<keyof BuildingInfoData, any>> = {};
    tempErrors.name = buildingInfo.name ? '' : 'Building name is required';
    tempErrors.address = buildingInfo.address ? '' : 'Address is required';
    tempErrors.city = buildingInfo.city ? '' : 'City is required';
    tempErrors.state = buildingInfo.state ? '' : 'State is required';
    tempErrors.zipCode = /^\d{5}(-\d{4})?$/.test(buildingInfo.zipCode) ? '' : 'Invalid ZIP code';
    tempErrors.squareFeet = +buildingInfo.squareFeet > 0 ? '' : 'Invalid square footage';
    tempErrors.yearBuilt = /^\d{4}$/.test(buildingInfo.yearBuilt) ? '' : 'Invalid year';
    tempErrors.propertyType = buildingInfo.propertyType ? '' : 'Property type is required';

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const hasChanges = useMemo(() => {
    return Object.keys(buildingInfo).some((key) => buildingInfo[key] !== originalInfo[key]);
  }, [buildingInfo, originalInfo]);

  const handleSubmit = async (event?: FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (validateForm()) {
      if (!hasChanges) {
        onSnackbar('No changes to save', 'info');
        return;
      }

      const result = await onUpdateProject({ id: project.id, data: { ...project.attributes, ...buildingInfo } });
      if (result.success) {
        setIsEditing(false);
        setOriginalInfo(buildingInfo);
        setBuildingInfo(buildingInfo);

        if (result.updatedProject) {
          setBuildingInfo(result.updatedProject.attributes);
          setOriginalInfo(result.updatedProject.attributes);
        }
      }
    } else {
      onSnackbar('Please correct the errors before submitting', 'error');
    }
  };

  const handleEdit = () => {
    if (isEditing) {
      setBuildingInfo(originalInfo);
      setErrors({});
    }
    setIsEditing(!isEditing);
    setActiveStep(0);
  };

  const handleServiceChange = (service: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedServices = event.target.checked
      ? [...buildingInfo.services, service]
      : buildingInfo.services.filter((s) => s !== service);

    const updatedInfo = { ...buildingInfo, services: updatedServices };
    setBuildingInfo(updatedInfo);
    calculateProgress(updatedInfo);
  };

  const steps = [
    { label: 'Basic Info', fields: ['name', 'propertyType'] },
    { label: 'Location', fields: ['address', 'city', 'state', 'zipCode'] },
    { label: 'Building Details', fields: ['squareFeet', 'yearBuilt'] },
    { label: 'Please pick your utility services', fields: ['services'] },
  ];

  const renderStepContent = (step: number) => (
    <Grid container spacing={2}>
      {steps[step].fields.map((field) => (
        <Grid item xs={12} key={field}>
          {field !== 'services' ? (
            field === 'propertyType' ? (
              <FormControl fullWidth error={!!errors[field]}>
                <InputLabel id="property-type-label">Property Type</InputLabel>
                <Select
                  labelId="property-type-label"
                  name="propertyType"
                  value={buildingInfo.propertyType}
                  onChange={handleInputChange}
                  error={!!errors[field]}
                >
                  {propertyTypeList.map((propertyType) => (
                    <MenuItem key={propertyType} value={propertyType}>
                      {propertyType}
                    </MenuItem>
                  ))}
                </Select>
                {errors[field] && (
                  <Typography variant="caption" color="error">
                    {errors[field]}
                  </Typography>
                )}
              </FormControl>
            ) : (
              <TextField
                fullWidth
                label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                name={field}
                value={buildingInfo[field as keyof Omit<BuildingInfoData, 'services'>]}
                onChange={handleInputChange}
                error={!!errors[field as keyof BuildingInfoData]}
                helperText={errors[field as keyof BuildingInfoData]}
                InputProps={{
                  startAdornment: (
                    <Box mr={1}>
                      {field === 'name' && <HomeIcon color="action" />}
                      {field === 'address' && <LocationCityIcon color="action" />}
                      {field === 'city' && <LocationCityIcon color="action" />}
                      {field === 'state' && <PinDropIcon color="action" />}
                      {field === 'zipCode' && <PinDropIcon color="action" />}
                      {field === 'squareFeet' && <SquareFootIcon color="action" />}
                      {field === 'yearBuilt' && <EventIcon color="action" />}
                      {field === 'propertyType' && <BriefcaseBusinessIcon color="action" />}
                    </Box>
                  ),
                }}
              />
            )
          ) : (
            <FormGroup>
              {UTILITY_SERIVCES.map((service) => (
                <FormControlLabel
                  key={service}
                  control={
                    <Checkbox
                      checked={buildingInfo.services.includes(service)}
                      onChange={handleServiceChange(service)}
                      name={service}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <BoltIcon color="action" style={{ marginRight: 8 }} />
                      <Typography>{service.charAt(0).toUpperCase() + service.slice(1)}</Typography>
                    </Box>
                  }
                />
              ))}
            </FormGroup>
          )}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <Typography variant="h5">Building Information</Typography>
          <Tooltip sx={{ mt: 3 }} title={isEditing ? 'Cancel Editing' : 'Edit Building Info'}>
            <IconButton onClick={handleEdit} color={isEditing ? 'secondary' : 'primary'}>
              {isEditing ? <CancelIcon /> : <EditIcon />}
            </IconButton>
          </Tooltip>
        </Box>

        {isEditing && (
          <Fade in={isEditing}>
            <div className={classes.editingIndicator}>
              <Typography variant="subtitle2">Editing Mode</Typography>
            </div>
          </Fade>
        )}

        <Collapse in={!isEditing}>
          <Grid container spacing={2}>
            {Object.entries(buildingInfo).map(([key, value]) => (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <Zoom in={true} style={{ transitionDelay: '100ms' }}>
                  <Card className={classes.cardWrapper}>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                      </Typography>
                      {key === 'services' ? (
                        <Typography variant="body1">{(value as string[]).join(', ') || 'None selected'}</Typography>
                      ) : (
                        <Typography variant="body1">{value || 'Not provided'}</Typography>
                      )}
                      <Tooltip title={`Enter ${key.replace(/([A-Z])/g, ' $1').toLowerCase()} information`}>
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </CardContent>
                  </Card>
                </Zoom>
              </Grid>
            ))}
          </Grid>
        </Collapse>

        <Collapse in={isEditing}>
          <form onSubmit={handleSubmit}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <Fade in={activeStep === index}>{renderStepContent(index)}</Fade>
                    <Box mt={2}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (activeStep === steps.length - 1) {
                            handleSubmit();
                          } else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                          }
                        }}
                      >
                        {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                      </Button>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </form>
        </Collapse>

        <Box className={classes.progressWrapper}>
          <Tooltip title={`${Math.round(progress)}% Complete`}>
            <LinearProgress variant="determinate" value={progress} />
          </Tooltip>
        </Box>
      </Paper>
    </div>
  );
};
