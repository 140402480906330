import { LogOutIcon, UserIcon } from 'lucide-react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  AvatarFallback,
  Button,
  NavigationMenuList,
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@shadcn/ui';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const placeholderUser = {
  attributes: {
    email: '',
    firstName: '',
  },
};

export const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { authUser, setAuthToken } = useJumboAuth();
  const user = !authUser ? placeholderUser : authUser;
  const userAttributes = user.attributes;

  const onLogout = () => {
    setAuthToken(null);
    navigate('/user/login');
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Avatar>
          <AvatarFallback className="hover:bg-primary/30">
            <UserIcon />
          </AvatarFallback>
        </Avatar>
      </PopoverTrigger>
      <PopoverContent className="rounded-xl border-border px-0" sideOffset={4} align="end">
        <div className="flex flex-col items-center gap-2">
          <Avatar className="h-12 w-12">
            <AvatarFallback>
              <UserIcon className="h-6 w-6" />
            </AvatarFallback>
          </Avatar>
          {userAttributes?.firstName ? <h5>{userAttributes.firstName}</h5> : null}
          {userAttributes?.email ? <p className="text-muted-foreground">{userAttributes.email}</p> : null}
        </div>
        <hr className="my-4 border-border" />
        <NavigationMenu orientation="vertical" className="flex max-w-none flex-col items-stretch gap-2">
          <NavigationMenuList className="flex-col items-stretch gap-4">
            <NavigationMenuItem>
              <NavigationMenuLink asChild>
                <Button variant="ghost" className="w-full justify-start gap-2 text-foreground" asChild>
                  <RouterLink className="no-underline" to="/account/profile">
                    <UserIcon />
                    Profile
                  </RouterLink>
                </Button>
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink asChild>
                <Button variant="ghost" className="w-full justify-start gap-2 text-foreground" onClick={onLogout}>
                  <LogOutIcon />
                  Logout
                </Button>
              </NavigationMenuLink>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </PopoverContent>
    </Popover>
  );
};
