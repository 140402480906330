import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Building2, TrendingDown, DollarSign, AlertTriangle, LucideIcon } from 'lucide-react';
import projectServices from 'app/services/project-services';

interface PenaltyData {
  squareFeet: number;
  annualThresholdCO2: number;
  yearsData: Array<{
    year: string;
    energySums: {
      electric: number;
      gas: number;
      steam: number;
      oil: number;
    };
    monthsCovered: number;
    actualEmissions: number;
    threshold: number;
  }>;
  estimatedAnnual: {
    energySums: {
      electric: number;
      gas: number;
      steam: number;
      oil: number;
    };
    emissions: number;
    penalty: number;
  };
}

interface EstimatedPenaltyProps {
  projectId: string;
}

interface InfoCardProps {
  icon: LucideIcon;
  title: string;
  value: string;
  theme: Theme;
  colorIndex?: number;
}

interface Theme {
  background: string;
  text: {
    primary: string;
    secondary: string;
    highlight: string;
  };
  card: {
    background: string;
    highlight: string;
  };
  chart: {
    colors: string[];
  };
}

const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
};

const formatNumber = (value: number): string => {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
};

const EstimatedPenalties = ({ projectId }: EstimatedPenaltyProps) => {
  const [penalties, setPenalties] = useState<PenaltyData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const theme = {
    background: '#111111', // Deep blue-gray
    text: {
      primary: '#ffffff', // White for primary text
      secondary: '#a0aec0', // Light blue-gray for secondary text
      highlight: '#7352C7', // Vibrant purple for highlights
    },
    card: {
      background: '#222222', // Lighter blue-gray for card backgrounds
      highlight: '#4a5568', // Even lighter, for hover effects
    },
    chart: {
      colors: ['#7352C7', '#4CAF50', '#F1D302', '#EAF2EF', '#CF6679'],
      // Purple, Green, Yellow, Teal, Pink
    },
  };

  useEffect(() => {
    const fetchPenalties = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await projectServices.getEstimatedPenalties(projectId);
        setPenalties(data);
      } catch (err) {
        setError('Failed to fetch penalty data. Please try again later.');
        console.error('Error fetching penalties:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPenalties();
  }, [projectId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!penalties) {
    return <div>No penalty data available.</div>;
  }

  const { squareFeet, annualThresholdCO2, yearsData, estimatedAnnual } = penalties;

  const latestYear = yearsData[yearsData.length - 1];
  const estimatedPenalty = estimatedAnnual.penalty;

  return (
    <div className="min-h-screen p-6 md:p-8" style={{ backgroundColor: theme.background }}>
      <div className="mx-auto max-w-7xl">
        <h1 className="mb-8 text-3xl font-bold md:text-4xl" style={{ color: theme.text.primary }}>
          Energy Usage and Carbon Emissions Analysis
        </h1>

        <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          <InfoCard
            icon={Building2}
            title="Building Size"
            value={`${formatNumber(squareFeet)} sq ft`}
            theme={theme}
            colorIndex={0}
          />
          <InfoCard
            icon={TrendingDown}
            title="Annual CO₂ Threshold"
            value={`${formatNumber(annualThresholdCO2)} tCO₂e`}
            theme={theme}
            colorIndex={1}
          />
          <InfoCard
            icon={AlertTriangle}
            title="Est. Annual Emissions"
            value={`${formatNumber(estimatedAnnual.emissions)} tCO₂e`}
            theme={theme}
            colorIndex={2}
          />
          <InfoCard
            icon={DollarSign}
            title="Est. Annual Penalty"
            value={formatCurrency(estimatedPenalty)}
            theme={theme}
            colorIndex={3}
          />
        </div>

        <div className="mb-8 rounded-xl bg-white bg-opacity-10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
          <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
            Emissions Trend Analysis
          </h2>
          <ResponsiveContainer width="100%" height={350}>
            <LineChart data={yearsData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" stroke={theme.text.secondary} />
              <XAxis dataKey="year" stroke={theme.text.secondary} />
              <YAxis yAxisId="left" orientation="left" stroke={theme.text.secondary} />
              <Tooltip contentStyle={{ backgroundColor: theme.card.background, borderColor: theme.text.secondary }} />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="actualEmissions"
                name="Actual Emissions"
                stroke={theme.chart.colors[0]}
                activeDot={{ r: 8 }}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="threshold"
                name="Emissions Threshold"
                stroke={theme.chart.colors[1]}
                strokeDasharray="5 5"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="mb-8 rounded-xl bg-white bg-opacity-10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
          <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
            Energy Usage Breakdown
          </h2>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={yearsData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" stroke={theme.text.secondary} />
              <XAxis dataKey="year" stroke={theme.text.secondary} />
              <YAxis stroke={theme.text.secondary} />
              <Tooltip contentStyle={{ backgroundColor: theme.card.background, borderColor: theme.text.secondary }} />
              <Legend />
              <Bar dataKey="energySumsKBTU.electric" name="Electricity" stackId="a" fill={theme.chart.colors[0]} />
              <Bar dataKey="energySumsKBTU.gas" name="Natural Gas" stackId="a" fill={theme.chart.colors[1]} />
              <Bar dataKey="energySumsKBTU.steam" name="Steam" stackId="a" fill={theme.chart.colors[2]} />
              <Bar dataKey="energySumsKBTU.oil" name="Oil" stackId="a" fill={theme.chart.colors[3]} />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="mb-8 overflow-x-auto rounded-xl bg-white bg-opacity-10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
          <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
            Yearly Energy and Emissions Summary
          </h2>
          <table className="w-full text-left">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Year
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Electricity (kWh)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Natural Gas (Therms)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Steam (mLbs)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Oil (gallons)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Actual Emissions (tCO₂e)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Months Covered
                </th>
              </tr>
            </thead>
            <tbody>
              {yearsData.map((year, index) => (
                <tr key={year.year} className={index % 2 === 0 ? 'bg-white bg-opacity-5' : ''}>
                  <td className="p-2 text-sm font-medium" style={{ color: theme.text.primary }}>
                    {year.year}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.energySums.electric)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.energySums.gas)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.energySums.steam)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.energySums.oil)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.actualEmissions)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {year.monthsCovered}
                  </td>
                </tr>
              ))}
              <tr className="bg-blue-500 bg-opacity-10 font-semibold">
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  Estimated Annual
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.energySums.electric)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.energySums.gas)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.energySums.steam)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.energySums.oil)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.emissions)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  12
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="rounded-xl bg-white bg-opacity-10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
          <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
            Recommendations
          </h2>
          <ul className="space-y-2 text-sm" style={{ color: theme.text.secondary }}>
            <li>
              Your estimated annual emissions ({formatNumber(estimatedAnnual.emissions)} tCO₂e) are{' '}
              {estimatedAnnual.emissions > annualThresholdCO2 ? 'above' : 'below'} the threshold (
              {formatNumber(annualThresholdCO2)} tCO₂e).
            </li>
            {estimatedAnnual.emissions > annualThresholdCO2 && (
              <li>
                Consider implementing energy efficiency measures to reduce your emissions and avoid potential penalties.
              </li>
            )}
            <li>
              Focus on reducing{' '}
              {latestYear?.energySums.electric > 0
                ? 'electricity'
                : latestYear?.energySums.steam > 0
                  ? 'steam'
                  : 'natural gas'}{' '}
              usage, as it's your primary source of emissions.
            </li>
            <li>Regularly monitor your energy consumption and emissions to stay on track with your reduction goals.</li>
            <li>Explore renewable energy options to further decrease your carbon footprint.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const InfoCard = ({ icon: Icon, title, value, theme, colorIndex = 0 }: InfoCardProps) => {
  return (
    <div
      className="rounded-xl p-4 shadow-md transition-all duration-300 ease-in-out hover:bg-opacity-80 hover:shadow-lg"
      style={{
        backgroundColor: theme.card.background,
      }}
    >
      <div className="mb-2 flex items-center">
        <Icon color={theme.text.highlight} className="h-6 w-6" />
        <h2 className="ml-2 text-lg font-semibold" style={{ color: theme.text.primary }}>
          {title}
        </h2>
      </div>
      <p className="text-2xl font-bold" style={{ color: theme.chart.colors[colorIndex] }}>
        {value}
      </p>
    </div>
  );
};

export default EstimatedPenalties;
