import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useToast } from '@shadcn/hooks/use-toast';
import documentServices, { UploadedDocument } from 'app/services/document-services';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentViewer } from './DocumentViewer';

export const DocumentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [document, setDocument] = useState<UploadedDocument | null>(null);
  const [editMode, setEditMode] = useState({});
  const [editedData, setEditedData] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(true);

  const { toast } = useToast();

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchDocument = async () => {
      try {
        return await documentServices.getById(id);
      } catch (error) {
        console.error('Error fetching document:', error);
        toast({
          description: 'Failed to fetch document. Please try again.',
          variant: 'destructive',
        });
      }
    };

    fetchDocument().then((fetchedDocument) => {
      if (isMounted) {
        setDocument(fetchedDocument);
        setEditedData(JSON.parse(JSON.stringify(fetchedDocument.attributes.detectedData)));
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [id, toast]);

  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleSave = async (field: string): Promise<void> => {
    if (!document) {
      return;
    }
    try {
      const newData = JSON.parse(JSON.stringify(document.attributes.detectedData));
      const fieldParts = field.split('.');
      let currentObj = newData;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        currentObj = currentObj[fieldParts[i]];
      }
      currentObj[fieldParts[fieldParts.length - 1]] = editedData[field];

      await documentServices.update({ id, data: { detectedData: newData } });

      setDocument({
        ...document,
        attributes: {
          ...document.attributes,
          detectedData: newData,
        },
      });
      setEditMode({ ...editMode, [field]: false });
      toast({
        title: 'Success',
        description: 'Document updated successfully.',
      });
    } catch (error) {
      console.error('Error updating document:', error);
      toast({
        title: 'Error',
        description: 'Failed to update the document. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleChange = (field, value) => {
    setEditedData({ ...editedData, [field]: value });
  };

  const handleReprocess = async () => {
    try {
      setIsLoading(true);
      await documentServices.update({ id, data: { status: 'queued', detectedData: null } });
      const updatedDocument = await documentServices.getById(id);
      setDocument(updatedDocument);
      setEditedData({});
      toast({
        title: 'Success',
        description: 'Document queued for reprocessing.',
      });
    } catch (error) {
      console.error('Error reprocessing document:', error);
      toast({
        title: 'Error',
        description: 'Failed to reprocess document. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await documentServices.delete(id);
      navigate('/dashboard/project');
      toast({
        title: 'Success',
        description: 'Document deleted successfully.',
      });
    } catch (error) {
      console.error('Error deleting document:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete the document. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const renderField = (field, value) => (
    <Box key={field} sx={{ mb: 2 }}>
      <Typography variant="subtitle2" sx={{ mb: 1, color: theme.palette.text.secondary }}>
        {field.split('.').pop().replace(/_/g, ' ')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {editMode[field] ? (
          <>
            <TextField
              size="small"
              value={
                editedData[field] ??
                (document?.attributes?.detectedData ? getNestedValue(document.attributes.detectedData, field) : '') ??
                ''
              }
              onChange={(e) => handleChange(field, e.target.value)}
              sx={{ flexGrow: 1, mr: 1 }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSave(field);
                }
              }}
            />
            <IconButton onClick={() => handleSave(field)} size="small" color="primary">
              <SaveIcon />
            </IconButton>
            <IconButton onClick={() => setEditMode({ ...editMode, [field]: false })} size="small">
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <>
            <TextField size="small" value={value ?? ''} disabled sx={{ flexGrow: 1, mr: 1 }} />
            <IconButton onClick={() => handleEdit(field)} size="small" color="primary">
              <EditIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );

  // data: detectedData
  const renderMetadataFields = (data: Record<string, any>, prefix = '') => {
    return Object.entries(data).map(([key, value]) => {
      const fullKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        return (
          <Accordion key={fullKey}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{key.replace(/_/g, ' ').toUpperCase()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ pl: 2 }}>{renderMetadataFields(value, fullKey)}</Box>
            </AccordionDetails>
          </Accordion>
        );
      } else {
        return renderField(fullKey, value);
      }
    });
  };

  const getNestedValue = (obj: Record<string, any>, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 3 }}>
        <Paper elevation={3} sx={{ display: 'flex', justifyContent: 'center', height: '100%', overflow: 'hidden' }}>
          <DocumentViewer url={document?.url} />
        </Paper>
      </Box>
      <Paper sx={{ width: 400, overflow: 'auto', p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Document Metadata
        </Typography>
        {document && document.attributes.detectedData && renderMetadataFields(document.attributes.detectedData)}
        <Box sx={{ mt: 3 }}>
          <Button variant="outlined" startIcon={<RefreshIcon />} onClick={handleReprocess} sx={{ mr: 1 }}>
            Reprocess
          </Button>
          <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={handleDelete}>
            Delete
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
