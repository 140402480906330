import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Chip,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useJumboTheme } from '@jumbo/hooks';
import userServices from 'app/services/user-services';

const defaultAvatarUrl =
  'https://static.vecteezy.com/system/resources/thumbnails/001/840/618/small/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg';

const Profile = () => {
  const { authUser } = useJumboAuth();
  // @ts-ignore
  const { theme } = useJumboTheme();
  const user = authUser?.attributes;
  const organizationName = authUser?.relationships?.organization?.data?.name;
  const avatar = user?.avatar || defaultAvatarUrl;

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [inviteError, setInviteError] = useState('');

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEmail('');
    setEmailError(false);
    setInviteSent(false);
    setInviteError('');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const handleSendRequest = async () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
    } else {
      try {
        await userServices.invite({ email });
        setInviteSent(true);
      } catch (error) {
        setInviteError('Failed to send invite. Please try again.');
      }
    }
  };

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 600,
        margin: 'auto',
        mt: 4,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box display="flex" alignItems="center" mb={3}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: theme.palette.success.main,
                border: `2px solid ${theme.palette.background.paper}`,
                width: 14,
                height: 14,
                borderRadius: '50%',
              },
            }}
          >
            <Avatar sx={{ width: 80, height: 80 }} alt={user?.email} src={avatar} />
          </Badge>
          <Box ml={3}>
            <Typography variant="h5" fontWeight="bold">
              {user?.name || 'User Name'}
            </Typography>
            <Chip icon={<BusinessIcon />} label={organizationName} size="small" sx={{ mt: 1 }} />
          </Box>
        </Box>

        <Box mb={3}>
          <Typography variant="body1" display="flex" alignItems="center" mb={1}>
            <EmailIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />
            {user?.email}
          </Typography>
        </Box>

        <Button variant="contained" color="primary" startIcon={<PersonAddIcon />} onClick={handleClickOpen} fullWidth>
          Invite User
        </Button>
      </CardContent>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invite to {organizationName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the email address of the user you'd like to invite to join the organization.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError && 'Please enter a valid email'}
          />
          {inviteSent && (
            <Typography color="success.main" mt={2}>
              Invitation sent successfully!
            </Typography>
          )}
          {inviteError && (
            <Typography color="error.main" mt={2}>
              {inviteError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {inviteSent ? 'Close' : 'Cancel'}
          </Button>
          <Button onClick={handleSendRequest} color="primary" variant="contained" disabled={!email || inviteSent}>
            Send Invitation
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Profile;
