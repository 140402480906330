import React from 'react';
import JumboThemeContext from '@jumbo/components/JumboTheme/JumboThemeContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';

/** @deprecated - can be removed after useJumboTheme is removed */
const JumboTheme = ({ children, init }) => {
  const [theme, setTheme] = React.useState(init.main);

  const updateTheme = React.useCallback(
    (options) => {
      const newTheme = createTheme({ ...theme, ...options });
      setTheme(newTheme);
    },
    [theme, setTheme]
  );

  const themeContextValue = React.useMemo(
    () => ({
      theme: createTheme(theme),
      setTheme: updateTheme,
    }),
    [theme]
  );

  return (
    <JumboThemeContext.Provider value={themeContextValue}>
      <ThemeProvider theme={themeContextValue.theme}>{children}</ThemeProvider>
    </JumboThemeContext.Provider>
  );
};

export default JumboTheme;
