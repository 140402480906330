import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import '../../../../node_modules/pdfjs-dist/build/pdf.worker.min.mjs';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '60px',
    height: '32px',
    minWidth: '50px',
    padding: 0,
    '&.Mui-disabled': {
      color: '#ffffff',
      // @ts-ignore
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  controlBox: {
    display: 'flex',
    alignItems: 'center',
    // @ts-ignore
    marginTop: theme.spacing(2),
  },
  pageInfo: {
    // @ts-ignore
    margin: `0 ${theme.spacing(2)}px`,
  },
}));

export const DocumentViewer = ({ url }) => {
  const classes = useStyles();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null);
  const [pageNum, setPageNum] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument({ url });
        const pdf = await loadingTask.promise;
        setPdfDoc(pdf);
        setPageCount(pdf.numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPDF();
  }, [url]);

  useEffect(() => {
    const renderPage = async () => {
      if (pdfDoc) {
        const page = await pdfDoc.getPage(pageNum);
        const scale = 1;
        const viewport = page.getViewport({ scale });

        const canvas = canvasRef.current;

        if (!canvas) {
          return;
        }

        const context = canvas.getContext('2d');

        if (!context) {
          throw new Error('Failed to get 2d context');
        }

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext: Parameters<typeof page.render>[0] = {
          canvasContext: context,
          viewport: viewport,
        };

        page.render(renderContext);
      }
    };

    renderPage();
  }, [pdfDoc, pageNum]);

  const changePage = (offset) => {
    setPageNum((prevPageNum) => {
      const newPageNum = prevPageNum + offset;
      return Math.min(Math.max(1, newPageNum), pageCount);
    });
  };

  return (
    <Box>
      <canvas ref={canvasRef}></canvas>
      <Box className={classes.controlBox}>
        <Button
          className={classes.button}
          sx={{ marginRight: '24px' }}
          variant="contained"
          onClick={() => changePage(-1)}
          disabled={pageNum <= 1}
        >
          Prev
        </Button>
        <span className={classes.pageInfo}>{`Page ${pageNum} of ${pageCount}`}</span>
        <Button
          className={classes.button}
          sx={{ marginLeft: '24px' }}
          variant="contained"
          onClick={() => changePage(1)}
          disabled={pageNum >= pageCount}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
