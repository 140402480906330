import { LAYOUT_NAMES } from 'app/layouts/layouts';
import { AppLayoutProvider } from 'app/utils/AppLayout';
import React, { PropsWithChildren, useState } from 'react';
import { LAYOUTS } from './utils/constants/layouts';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

export const AppLayout = (props: PropsWithChildren<{ activeLayout: LAYOUT_NAMES }>) => {
  const { isLoading } = useJumboAuth();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeLayout, setActiveLayout] = useState(props.activeLayout);

  if (!activeLayout) {
    throw Error('AppLayout > No activeLayout is set.');
  }

  const LayoutComponent = React.useMemo(() => {
    const layoutIndex = LAYOUTS.findIndex((layout) => layout.name === activeLayout);

    if (layoutIndex >= 0) {
      return LAYOUTS[layoutIndex].component;
    }

    throw Error('No activeLayout is set yet.');
  }, [activeLayout]);

  return (
    <AppLayoutProvider value={{ sidebarOpen, setSidebarOpen, activeLayout, setActiveLayout }}>
      {isLoading ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : (
        <LayoutComponent>{props.children}</LayoutComponent>
      )}
    </AppLayoutProvider>
  );
};
