import { RouteObject } from 'react-router/dist/lib/context';

const addRouteObjectToTree = (routeTree: RouteObject[], routes: RouteObject[]) => {
  const routeObject = routes[0];
  routes.shift();
  if (routeObject.hasOwnProperty('children')) {
    const { children, ...restRouteProperties } = routeObject;
    const nextNode: RouteObject = { ...restRouteProperties };
    nextNode.children = [];
    routeTree.push(nextNode);
    buildRoutesTree(nextNode.children, routeObject.children ?? []);
  } else {
    if (Array.isArray(routeObject?.path)) {
      routeObject.path.forEach((path) => {
        routeTree.push({
          path: path,
          element: routeObject.element,
        });
      });
    } else {
      routeTree.push(routeObject);
    }
  }
};

// TODO: avoid mutation
const buildRoutesTree = (routeTree: RouteObject[], routes: RouteObject[]) => {
  addRouteObjectToTree(routeTree, routes);
  if (routes.length > 0) {
    buildRoutesTree(routeTree, routes);
  }
};

export const buildRoutes = (routes: RouteObject[]) => {
  const routeTree: RouteObject[] = [];
  if (Array.isArray(routes) && routes.length > 0) {
    buildRoutesTree(routeTree, routes);
    return routeTree;
  } else {
    throw Error("routes must be an array of object with at least a key 'element'");
  }
};
