import * as React from 'react';
import { useState, useEffect, ReactNode } from 'react';
import * as yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Facebook, Google, Twitter } from '@mui/icons-material';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { AuthPageShell } from 'app/pages/auth-pages/AuthPageShell';
import { AppLink } from 'app/reusable/Link';
import { Button } from '@shadcn/ui';
import { FormikInputWrapper } from '@shadcn/custom/FormikInputWrapper';

import authServices from 'app/services/auth-services';
import { trackGoogleAnalyticsEvent } from 'app/utils/google-analytics';
import { SignUpCategory, SignUpCompletedAction } from 'app/utils/google-analytics/events/signUp';
import { LoginCategory, LoginCompletedAction } from 'app/utils/google-analytics/events/login';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const typeToConfig = {
  login: {
    text: 'LOG IN',
    authMethod: authServices.signIn,
  },
};

export const Login = ({ disableSmLogin, type }) => {
  const { tempToken } = useParams();
  const { setAuthToken } = useJumboAuth();
  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState('');

  const errorHandler = (error) => {
    if (error?.response) {
      const errors = error.response?.data?.errors;
      const errorObject = errors[0];
      const message = errorObject?.detail || 'Looks like there was an error. Please try again!';
      setServerMessage(message);
    }
  };

  const loginWithToken = async (tempToken) => {
    try {
      const currentUser = await authServices.getUserByTempToken(tempToken);
      if (currentUser?.attributes?.token) {
        setAuthToken(currentUser.attributes.token);
        navigate('/dashboard/project');
        trackGoogleAnalyticsEvent(LoginCategory, LoginCompletedAction, window.location.pathname);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (tempToken) {
      loginWithToken(tempToken);
    }
  }, [tempToken]);

  const config = typeToConfig[type || 'login'];

  const onGoogleSignIn = async (payload) => {
    setServerMessage('');
    try {
      const response = await authServices.signInWithGoogle(payload);
      if (response?.attributes?.token) {
        setAuthToken(response?.attributes?.token);
        navigate('/dashboard/project');
        if (!type) {
          trackGoogleAnalyticsEvent(LoginCategory, LoginCompletedAction, window.location.pathname);
        }
        if (type === 'signup') {
          trackGoogleAnalyticsEvent(SignUpCategory, SignUpCompletedAction, window.location.pathname);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const onSubmit = async (email, password) => {
    setServerMessage('');

    try {
      const authMethod = config.authMethod;
      const response = await authMethod({ email, password });
      if (response?.attributes?.token) {
        setAuthToken(response?.attributes?.token);
        navigate('/dashboard/project');
        if (!type) {
          trackGoogleAnalyticsEvent(LoginCategory, LoginCompletedAction, window.location.pathname);
        }
        if (type === 'signup') {
          trackGoogleAnalyticsEvent(SignUpCategory, SignUpCompletedAction, window.location.pathname);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const renderForm = (): ReactNode => (
    <div className="flex flex-col gap-4">
      <Formik
        validateOnChange={true}
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(data.email, data.password);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col gap-4 text-left" noValidate autoComplete="off">
            <FormikInputWrapper label="Email" name="email" placeholder="you@company.com" />
            <FormikInputWrapper label="Password" name="password" placeholder="Password" type="password" />
            {serverMessage ? <p className="bg-destructive/25 text-destructive-foreground">{serverMessage}</p> : null}
            <Button className="w-full" type="submit" variant="default" size="lg" loading={isSubmitting}>
              {config.text}
            </Button>
            {disableSmLogin ? null : (
              <>
                <p className="mb-2">Or sign in with</p>
                <div className="mb-1 flex flex-row items-center gap-1">
                  <Button size="icon" aria-label="Facebook" className="rounded-full bg-[#385196] hover:bg-[#385196]">
                    <Facebook />
                  </Button>
                  <Button size="icon" aria-label="Twitter" className="rounded-full bg-[#00a8ff] hover:bg-[#00a8ff]">
                    <Twitter />
                  </Button>
                  <Button size="icon" aria-label="Google" className="rounded-full bg-[#23272b] hover:bg-[#23272b]">
                    <Google />
                  </Button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
      {type === 'signup' ? (
        <h5>
          Already have an account? <AppLink to="/user/login">Log in</AppLink>
        </h5>
      ) : null}
    </div>
  );

  return <AuthPageShell rightContent={renderForm()} />;
};
