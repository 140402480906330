import { SidebarCloseIcon, SidebarOpenIcon } from 'lucide-react';
import { Button } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import Logo from 'app/shared/Logo';
import { useAppLayout } from 'app/utils/AppLayout';

export const SidebarHeader = () => {
  const { sidebarOpen, setSidebarOpen } = useAppLayout();

  return (
    <>
      <div
        className={cn('flex flex-row items-center justify-between gap-6', {
          'ml-6': sidebarOpen,
        })}
      >
        <Logo />
        <Button variant="ghost" size="icon" className="rounded-full" onClick={() => setSidebarOpen((v) => !v)}>
          {sidebarOpen ? <SidebarCloseIcon /> : <SidebarOpenIcon />}
        </Button>
      </div>
    </>
  );
};
