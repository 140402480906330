import { cn } from '@shadcn/utils';
import { useState, useEffect } from 'react';
import { useToast } from '@shadcn/hooks/use-toast';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogHeader,
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
  Label,
} from '@shadcn/ui';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import projectServices from 'app/services/project-services';
import { UTILITY_SERIVCES } from 'app/utils/constants/utilityServices';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Building2Icon,
  CheckCircleIcon,
  EllipsisVerticalIcon,
  HouseIcon,
  MapPinIcon,
} from 'lucide-react';

const steps = [
  { label: 'Basic Info', icon: HouseIcon },
  { label: 'Property Details', icon: Building2Icon },
  { label: 'Location', icon: MapPinIcon },
];

type ProjectInfoData = {
  name: string;
  propertyType: string;
  squareFeet: string;
  yearBuilt: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  services: string[];
};

const initialProjectInfo: ProjectInfoData = {
  name: '',
  propertyType: '',
  squareFeet: '',
  yearBuilt: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  services: [],
};

export const ProjectModal = ({ open, setOpen }: { open: boolean; setOpen: (v: boolean) => void }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [projectInfo, setProjectInfo] = useState<ProjectInfoData>(initialProjectInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Partial<Record<keyof ProjectInfoData, string>>>({});
  const [isCompleted, setIsCompleted] = useState(true);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const { toast } = useToast();

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setIsCompleted(false);
    }
  }, [open]);

  useEffect(() => {
    let isMounted = true;

    const fetchPropertyList = async () => {
      try {
        const propertyTypes = await projectServices.getPropertyTypes();
        if (isMounted) {
          setPropertyTypeList(propertyTypes);
        }
      } catch (error) {
        // TODO: implement
        console.log('cant get property types list');
      }
    };

    fetchPropertyList();

    return () => {
      isMounted = false;
    };
  }, []);

  const validateStep = (step) => {
    const errors: Partial<Record<keyof ProjectInfoData, string>> & { _general?: string } = {};
    switch (step) {
      case 0:
        if (!projectInfo.name) errors.name = 'Project name is required';
        break;
      case 1:
        if (!projectInfo.propertyType) errors.propertyType = 'Property type is required';
        if (!projectInfo.squareFeet) errors.squareFeet = 'Square feet is required';
        if (!/^\d{4}$/.test(projectInfo.yearBuilt)) errors.yearBuilt = 'Year built must be exactly 4 numbers';
        if (projectInfo.services.length === 0) errors.services = 'At least 1 service is required';
        break;
      case 2:
        if (!projectInfo.address) errors.address = 'Address is required';
        if (!projectInfo.city) errors.city = 'City is required';
        if (projectInfo.state.length !== 2) errors.state = 'State must be exactly 2 characters';
        if (!/^\d{5}$/.test(projectInfo.zipCode)) errors.zipCode = 'Zip code must be exactly 5 numbers';
        break;
      default:
        console.error(`Unexpected step value: ${step}`);
        errors._general = 'An unexpected error occurred. Please try again.';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log(event.target);

    if (type === 'checkbox') {
      setProjectInfo((prev) => ({
        ...prev,
        services: checked ? [...prev.services, value] : prev.services.filter((service) => service !== value),
      }));
    } else {
      setProjectInfo((prev) => ({ ...prev, [name]: value }));
    }

    setValidationErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleCreateProject = async () => {
    if (!validateStep(activeStep)) {
      return;
    }

    setIsLoading(true);

    try {
      const params = {
        ...projectInfo,
        squareFeet: parseInt(projectInfo.squareFeet),
        yearBuilt: parseInt(projectInfo.yearBuilt),
      };

      const project = await projectServices.create(params);

      if (project) {
        setIsCompleted(true);
      } else {
        throw new Error('Failed to create project. Please try again.');
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: err instanceof Error ? err.message : 'An unexpected error occurred. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeAndClearModal = () => {
    setProjectInfo({
      name: '',
      propertyType: '',
      squareFeet: '',
      yearBuilt: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      services: [],
    });
    setValidationErrors({});
    setActiveStep(0);
    setIsCompleted(false);
    setOpen(false);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <InputWrapper
            placeholder="Project Name"
            name="name"
            value={projectInfo.name}
            onChange={handleInputChange}
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />
        );
      case 1:
        return (
          <div className="flex flex-col gap-4">
            <div>
              <Select
                name="propertyType"
                value={projectInfo.propertyType}
                onValueChange={(newVal) => setProjectInfo((prev) => ({ ...prev, propertyType: newVal }))}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Property Type" />
                </SelectTrigger>
                <SelectContent>
                  {propertyTypeList.map((propertyType) => (
                    <SelectItem key={propertyType} value={propertyType} className="cursor-pointer">
                      {propertyType}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {validationErrors.propertyType ? (
                <p className="bg-destructive/25 text-destructive-foreground">{validationErrors.propertyType}</p>
              ) : null}
            </div>
            <div className="flex flex-row gap-4">
              <InputWrapper
                label="Square Feet"
                name="squareFeet"
                type="number"
                value={projectInfo.squareFeet}
                onChange={handleInputChange}
                error={!!validationErrors.squareFeet}
                helperText={validationErrors.squareFeet}
              />
              <InputWrapper
                label="Year Built"
                name="yearBuilt"
                value={projectInfo.yearBuilt}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: 'yearBuilt',
                      value: e.target.value.replace(/\D/g, '').slice(0, 4),
                    },
                  })
                }
                error={!!validationErrors.yearBuilt}
                helperText={validationErrors.yearBuilt}
                maxLength={4}
                pattern="\\d*"
              />
            </div>
            <div className="mt-4 flex flex-col gap-4">
              <Label className="text-muted-foreground">Choose your utility services</Label>
              <div className="flex flex-col gap-2">
                {UTILITY_SERIVCES.map((service) => (
                  <div className="flex flex-row gap-2">
                    <Checkbox
                      id={`service-${service}`}
                      key={service}
                      checked={projectInfo.services.includes(service)}
                      onCheckedChange={(checked) => {
                        setProjectInfo((prev) => ({
                          ...prev,
                          services: checked ? [...prev.services, service] : prev.services.filter((s) => s !== service),
                        }));
                      }}
                      name={service}
                      value={service}
                    />
                    <Label className="flex cursor-pointer items-center gap-2" htmlFor={`service-${service}`}>
                      {/* TODO: icons? */}
                      {service.charAt(0).toUpperCase() + service.slice(1)}
                    </Label>
                  </div>
                ))}
                <p className="text-xs text-muted-foreground">{validationErrors.services || 'Select all that apply'}</p>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="flex flex-col gap-4">
            <InputWrapper
              label="Address"
              name="address"
              value={projectInfo.address}
              onChange={handleInputChange}
              error={!!validationErrors.address}
              helperText={validationErrors.address}
            />
            <div className="flex flex-row gap-4">
              <div>
                <InputWrapper
                  label="City"
                  name="city"
                  value={projectInfo.city}
                  onChange={handleInputChange}
                  error={!!validationErrors.city}
                  helperText={validationErrors.city}
                />
              </div>
              <div>
                <InputWrapper
                  label="State"
                  name="state"
                  value={projectInfo.state}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: 'state',
                        value: e.target.value.toUpperCase().slice(0, 2),
                      },
                    })
                  }
                  error={!!validationErrors.state}
                  helperText={validationErrors.state}
                  maxLength={2}
                />
              </div>
              <div>
                <InputWrapper
                  label="Zip Code"
                  name="zipCode"
                  value={projectInfo.zipCode}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: 'zipCode',
                        value: e.target.value.replace(/\D/g, '').slice(0, 5),
                      },
                    })
                  }
                  error={!!validationErrors.zipCode}
                  helperText={validationErrors.zipCode}
                  maxLength={5}
                  pattern="\\d*"
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(v) => {
        if (!v) {
          closeAndClearModal();
        }
      }}
    >
      <DialogContent className="p-0 pb-6">
        <DialogHeader>
          <DialogTitle className="bg-primary px-6 py-4 text-primary-foreground">Create New Project</DialogTitle>
        </DialogHeader>
        <div className="flex flex-row content-start gap-6 p-4 pt-2">
          <div className="grid auto-cols-min auto-rows-min grid-cols-[auto_1fr] content-start items-center gap-2">
            {steps.map((step, index) => (
              <>
                <step.icon
                  className={cn('transition-300 text-secondary-foreground transition-colors ease-in-out', {
                    'text-primary': activeStep === index,
                  })}
                />
                <p
                  className={cn('whitespace-nowrap', {
                    'font-bold': activeStep === index,
                  })}
                >
                  {step.label}
                </p>
                {index < steps.length - 1 ? (
                  <>
                    <EllipsisVerticalIcon />
                    <div />
                  </>
                ) : null}
              </>
            ))}
          </div>
          <div
            className={cn('flex grow flex-col justify-start', {
              'items-center justify-center': isCompleted,
            })}
          >
            {isCompleted ? (
              <div className="flex flex-col items-center justify-center gap-4">
                <CheckCircleIcon className="h-10 w-10 text-chart-2" />
                <h5>Project Created Successfully!</h5>
              </div>
            ) : (
              <div>{renderStepContent(activeStep)}</div>
            )}
          </div>
        </div>
        <DialogFooter className="w-full justify-between gap-2 px-6">
          {!isCompleted ? (
            <>
              <Button variant="secondary" disabled={activeStep === 0} onClick={handleBack}>
                <ArrowLeftIcon /> Back
              </Button>
              <Button
                type="submit"
                onClick={activeStep === steps.length - 1 ? handleCreateProject : handleNext}
                disabled={isLoading || isCompleted}
                loading={isLoading}
              >
                {activeStep === steps.length - 1 ? 'Create Project' : 'Next'}
                {activeStep === steps.length - 1 ? null : <ArrowRightIcon />}
              </Button>
            </>
          ) : (
            <Button onClick={closeAndClearModal}>Close</Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
