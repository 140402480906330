import { RouteObject } from 'react-router-dom';
import { Page } from '@jumbo/shared/Page';
import { Login } from 'app/pages/auth-pages/login';
import { ResetPassword } from 'app/pages/auth-pages/reset-password';
import { SetPassword } from 'app/pages/auth-pages/set-password';
import Profile from 'app/pages/profile/Profile';
import { Privacy } from 'app/pages/privacy/Privacy';
import { TermsOfService } from 'app/pages/terms-of-service/TermsOfService';
import { APIKeys } from 'app/pages/api-keys';
import { PricingPlan } from 'app/pages/billing';
import { Landing } from 'app/pages/auth-pages/landing';
import { Project } from 'app/pages/project/Project';
import { ProjectDetail } from 'app/pages/project/ProjectDetail';
import { DocumentDetail } from 'app/pages/documents/DocumentDetail';
import { LAYOUT_NAMES } from 'app/layouts/layouts';

/**
 routes accessible to both authenticated and anonymous users
 **/
const routesForPublic: RouteObject[] = [
  {
    path: '/',
    element: <Page component={Landing} layout={LAYOUT_NAMES.SOLO_PAGE} disableSmLogin={true} />,
  },
  {
    path: '/privacy',
    element: <Page component={Privacy} layout={LAYOUT_NAMES.SOLO_PAGE} />,
  },
  {
    path: '/terms-of-service',
    element: <Page component={TermsOfService} layout={LAYOUT_NAMES.SOLO_PAGE} />,
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly: RouteObject[] = [
  {
    path: '/dashboard/project',
    element: <Page layout={LAYOUT_NAMES.VERTICAL_DEFAULT} component={Project} />,
  },
  {
    path: '/dashboard/project/:id',
    element: <Page layout={LAYOUT_NAMES.VERTICAL_DEFAULT} component={ProjectDetail} />,
  },
  {
    path: '/dashboard/document/:id',
    element: <Page layout={LAYOUT_NAMES.VERTICAL_DEFAULT} component={DocumentDetail} />,
  },
  {
    path: '/account/profile',
    element: <Page layout={LAYOUT_NAMES.VERTICAL_DEFAULT} component={Profile} />,
  },
  {
    path: '/account/billing',
    element: <Page layout={LAYOUT_NAMES.VERTICAL_DEFAULT} component={PricingPlan} />,
  },
  {
    path: '/account/apiKeys',
    element: <Page layout={LAYOUT_NAMES.VERTICAL_DEFAULT} component={APIKeys} />,
  },
];

/**
 routes only accessible to anonymous users
 **/
const routesForNotAuthenticatedOnly: RouteObject[] = [
  {
    path: '',
    element: <Page component={Landing} layout={LAYOUT_NAMES.SOLO_PAGE} disableSmLogin={true} />,
  },
  {
    path: '/user/login',
    element: <Page component={Login} layout={LAYOUT_NAMES.SOLO_PAGE} disableSmLogin={true} />,
  },
  {
    path: '/user/login/:tempToken',
    element: <Page component={Login} layout={LAYOUT_NAMES.SOLO_PAGE} disableSmLogin={true} />,
  },
  // {
  //   path: '/user/signup',
  //   element: <Page component={Login} layout={LAYOUT_NAMES.SOLO_PAGE} disableSmLogin={true} type="signup" />
  // },
  {
    path: '/user/reset-password',
    element: <Page component={ResetPassword} layout={LAYOUT_NAMES.SOLO_PAGE} />,
  },
  {
    path: '/user/set-password/:tempToken',
    element: <Page component={SetPassword} layout={LAYOUT_NAMES.SOLO_PAGE} />,
  },
];

// NOTE: routes _should_ be nestable via this config approach
// -- when https://reactrouter.com/en/main/route/route#children is populated, refactor this

const routes = [...routesForPublic, ...routesForAuthenticatedOnly, ...routesForNotAuthenticatedOnly];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };
